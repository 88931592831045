import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';
import { Menu } from '../../components/Menu';
import { callApi } from '../../functions/callApi';
import { centsToDollars } from '../../functions/formatters';
import { invoiceShape } from '../../interfaces/user';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { SideDataGrid } from '../../components/SideDataGrid';
import { Button } from '../../components/Button';
import { SideDataGridItem } from '../../components/SideDataGridItem';

declare global {
  interface Window {
    myProperty: string;
  }
}

function Invoices(): ReactElement {
  const navigate = useNavigate();
  const { userState, setUserState } = useGlobalUserState();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const getInvoices = async (): Promise<void> => {
      const invoices = await callApi<any>('billing/invoices', 'GET');
      if (!invoices.error) {
        setUserState((prevState: any) => ({
          ...prevState,
          data: {
            ...prevState.data,
            invoices,
          },
        }));
      }
    };
    getInvoices();
  }, []);

  useEffect(() => {
    !userState.data?.loggedIn && navigate('/');
  }, [userState]);

  const calcNav = (): any[] => {
    const navArr = [
      { name: 'Usage', path: '/billing/usage' },
      { name: 'Funding', path: '/billing/funding' },
      { name: 'Invoices', path: '/billing/invoices' },
    ];
    return navArr;
  };

  const selectFile = (x: any): void => {
    const fileArr = userState.data?.invoices;
    if (fileArr) {
      const selected: any = fileArr[x.target.id] as invoiceShape;
      setSelectedFile(selected);
      setIsOpen(true);
    }
  };

  return (
    <div className='Usage'>
      <Header />
      <div className='Usage--center'>
        <div className='Usage--title'>Billing</div>
        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          position='RIGHT'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title={selectedFile?.period_name || ''}
              subtitle='This is the invoice for this period'
            />
            <div>
              <br />
              <br />
              {selectedFile?.charges.length > 0 && (
                <>
                  <SideDataGrid description='Paid'>
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Amount: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `$${selectedFile?.charges[0].amount}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Status: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedFile?.charges[0].status}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Card type: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedFile?.charges[0].accountpaymentdetails.card_brand}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Last four: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedFile?.charges[0].accountpaymentdetails.card_last_four}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Stripe invoice id: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedFile?.charges[0].stripe_invoice_id}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                  </SideDataGrid>
                  {selectedFile?.db_invoices.length > 0 && (
                    <a
                      href={`${selectedFile?.db_invoices[0]?.stripe_invoice_link}`}
                      target='_blank'
                    >
                      <Button name='Open Invoice' />
                    </a>
                  )}
                </>
              )}
              {!selectedFile?.charges.length && (
                <>
                  <SideDataGrid description='Invoice'>
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Total: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `$${
                              selectedFile?.total_us_cents_to_pay / 100
                            }`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Status: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedFile?.status}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Stripe invoice id: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedFile?.invoice_stripe_id}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                  </SideDataGrid>
                  {selectedFile?.db_invoices.length > 0 && (
                    <a
                      href={`${selectedFile?.db_invoices[0]?.stripe_invoice_link}`}
                      target='_blank'
                    >
                      <Button name='Open Invoice' />
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
        </SideMenu>
        <div className='Usage--grid'>
          <div>
            <Menu items={calcNav()} selected='Invoices' />
          </div>
          <div>
            <div className='Users--info'>
              <div className='Users--info-title'>
                <div className='Users--info-title-text'>Invoices</div>
                {userState.data?.maxThreshold && (
                  <div style={{ padding: '14px' }}>
                    You are invoiced monthly or when spending reaches $
                    <b>{userState.data?.maxThreshold || ''}</b>
                  </div>
                )}
              </div>
              {userState?.data?.invoices && (
                <div className='Users--info-header'>
                  <div>Total</div>
                  <div>Period</div>
                  <div>Status</div>
                </div>
              )}
              {userState?.data?.invoices?.length &&
                userState?.data?.invoices?.map((invoice: any, index: any) => (
                  <div
                    id={index}
                    key={index}
                    className='Users--info-row'
                    onClick={selectFile}
                  >
                    <div
                      style={{
                        fontWeight:
                          invoice.db_invoices[0].status === 'open'
                            ? '700'
                            : '200',
                      }}
                    >
                      {`$${centsToDollars(invoice.total_us_cents_to_pay!, 2)}`}
                    </div>
                    <div
                      style={{
                        fontWeight:
                          invoice.db_invoices[0].status === 'open'
                            ? '700'
                            : '200',
                      }}
                    >
                      {invoice.period_name || ''}
                    </div>
                    <div
                      style={{
                        textTransform: 'capitalize',
                        fontWeight:
                          invoice.db_invoices[0].status === 'open'
                            ? '700'
                            : '200',
                      }}
                    >
                      {invoice.db_invoices[0].status}
                    </div>
                  </div>
                ))}
              {!userState?.data?.invoices && (
                <div className='Users--none'>
                  No invoices have been raised yet.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoices;
