import { ReactElement, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

export interface PropsShape {
  name: string;
  children?: ReactElement;
}

const UserMenu = ({ name, children }: PropsShape): ReactElement => {
  const menuTransition = useRef<null | HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className='UserMenu'>
      {isOpen && (
        <div
          className='UserMenu--overlay'
          onClick={(): void => setIsOpen(!isOpen)}
        ></div>
      )}
      <div
        className='UserMenu--button'
        onClick={(): void => setIsOpen(!isOpen)}
      >
        <div className='UserMenu--avatar' />
        <div className='UserMenu--name'>{name}</div>
      </div>
      <CSSTransition
        nodeRef={menuTransition}
        in={isOpen}
        timeout={300}
        classNames={`UserMenu--transition`}
        unmountOnExit
      >
        <div className='UserMenu--menu' ref={menuTransition}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};
export { UserMenu };
