import { ReactElement, useState, useEffect } from 'react';
import { Header } from '../components/Header';
import SideMenu from '../components/SideMenu';
import { Heading } from '../components/Heading';
import { TextInput } from '../components/TextInput';
import { Password } from '../components/Password';
import { Button } from '../components/Button';
import { isPassword, isEmail } from '../functions/validators';
import { callApi } from '../functions/callApi';
import { useSearchParams } from 'react-router-dom';
import { ServerError } from '../components/ServerError';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';

function ChangePassword(): ReactElement {
  const navigate = useNavigate();
  const { setUserState } = useGlobalUserState();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') || '';
  const [error, setError] = useState<string>('');

  const changePassword = async (): Promise<void> => {
    setError('');
    setLoading(true);

    const result = await callApi<any>(
      'account/change-password',
      'POST',
      JSON.stringify({
        code,
        password,
      })
    );
    if (result?.statusCode === 400) {
      setError(
        'Your password reset link has expired, please request a fresh one '
      );
    } else {
      const loginResult = await callApi<any>(
        'auth/local/login',
        'POST',
        JSON.stringify({
          email,
          password,
        })
      );
      loginResult.message && setError(result.message);
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          loggedIn: true,
          accountKey: loginResult.account_key,
          accessToken: loginResult.access_token,
          name: loginResult.owner_name,
          email: loginResult.owner_email,
          lastUpload: Date.now(),
          userRole: loginResult.account_role.type,
          uuid: loginResult.account_key,
          pulse: {
            accountDeletedMail: loginResult.account_deleted_mail,
            accountDeletedPulse: loginResult.account_deleted_pulse,
            accountFrozenMail: loginResult.account_frozen_mail,
            accountFrozenPulse: loginResult.account_frozen_pulse,
            failedPaymentMail: loginResult.failed_payment_mail,
            failedPaymentPulse: loginResult.failed_payment_pulse,
            invoiceGeneratedMail: loginResult.invoice_generated_mail,
            invoiceGeneratedPulse: loginResult.invoice_generated_pulse,
            noPaymentMethodMail: loginResult.no_payment_method_mail,
            noPaymentMethodPulse: loginResult.no_payment_method_pulse,
            successfulPaymentMail: loginResult.successful_payment_mail,
            successfulPaymentPulse: loginResult.successful_payment_pulse,
            thresholdNotReachedMail: loginResult.threshold_not_reached_mail,
            thresholdNotReachedPulse: loginResult.threshold_not_reached_pulse,
          },
        },
      }));
      setIsOpen(false);
      navigate('/files');
    }
  };

  useEffect(() => {
    let validpass: boolean = false;
    let validemail: boolean = false;
    validpass = isPassword(password);
    validemail = isEmail(email);
    setDisabled(!(validpass && validemail));
  }, [email, password]);

  return (
    <div className='ChangePassword'>
      <Header />
      <div className='ChangePassword--center'>
        <SideMenu isOpen={isOpen} setIsOpen={setIsOpen} position='CENTER'>
          <>
            <div className='ChangePassword--modal'>
              <Heading
                title='Change Password'
                subtitle={`Please enter your email address and a new password below.`}
              />
              <ServerError error={error} />
              <TextInput
                type='email'
                name='Email'
                value={email}
                placeHolder='Your email address'
                setValue={setEmail}
                focus
              />
              <Password
                name='New Password'
                value={password}
                setValue={setPassword}
                strong
              />
              <Button
                name='Change'
                click={changePassword}
                loading={loading}
                disabled={disabled}
              />
            </div>
          </>
        </SideMenu>
      </div>
    </div>
  );
}

export default ChangePassword;
