import { ReactElement, useState, useEffect, useContext } from 'react';
import SideMenu from './SideMenu';
import { Heading } from './Heading';
import { TextInput } from './TextInput';
import { Password } from './Password';
import { Button } from './Button';
import { callApi } from '../functions/callApi';
import { ServerError } from './ServerError';
import { isEmail } from '../functions/validators';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';
import { SendMessageContext } from '../providers/SendMessageProvider';

const SignIn = (): ReactElement => {
  const { sendMessage } = useContext(SendMessageContext);
  const navigate = useNavigate();
  const { userState, setUserState } = useGlobalUserState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [info, setInfo] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [loading2Fa, setLoading2Fa] = useState<boolean>(false);

  const [view, setView] = useState<
    'SIGN' | 'FORGOT' | 'INVITE' | 'SENT' | 'INVITED' | 'TWOFA'
  >('SIGN');

  (window as any).global = window;

  const handleLogIn = async (
    result: any,
    enabled2Fa: boolean
  ): Promise<void> => {
    sendMessage({
      message: `${result.owner_name} logged in`,
      messageType: 'Notification',
    });
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        enabled2Fa,
        maxThreshold: result.maximum_threshold_usd,
        isPreviewOn: result.is_preview_on,
        loggedIn: true,
        accountKey: result.account_key,
        accessToken: result.access_token,
        name: result.owner_name,
        email: result.owner_email,
        lastUpload: Date.now(),
        userRole: result.account_role.type,
        isNodeOperator: result.has_outposts,
        uuid: result.account_key,
        has_credit_card: result.has_credit_card,
        pulse: {
          accountDeletedMail: result.account_deleted_mail,
          accountDeletedPulse: result.account_deleted_pulse,
          accountFrozenMail: result.account_frozen_mail,
          accountFrozenPulse: result.account_frozen_pulse,
          failedPaymentMail: result.failed_payment_mail,
          failedPaymentPulse: result.failed_payment_pulse,
          invoiceGeneratedMail: result.invoice_generated_mail,
          invoiceGeneratedPulse: result.invoice_generated_pulse,
          noPaymentMethodMail: result.no_payment_method_mail,
          noPaymentMethodPulse: result.no_payment_method_pulse,
          successfulPaymentMail: result.successful_payment_mail,
          successfulPaymentPulse: result.successful_payment_pulse,
          thresholdNotReachedMail: result.threshold_not_reached_mail,
          thresholdNotReachedPulse: result.threshold_not_reached_pulse,
        },
      },
    }));
    setIsOpen(false);
    navigate('/files');
  };

  const handle2Fa = async (): Promise<void> => {
    setLoading2Fa(true);
    try {
      const result = await callApi<any>(
        'auth/local/login-2fa',
        'POST',
        JSON.stringify({
          email,
          token,
        })
      );
      if (result.access_token) {
        handleLogIn(result, true);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setLoading2Fa(false);
    }
  };

  const signIn = async (): Promise<void> => {
    setLoading(true);
    if (!isEmail(email)) {
      setLoading(false);
      setError('Invalid email');
    } else {
      try {
        const result = await callApi<any>(
          'auth/local/login',
          'POST',
          JSON.stringify({
            email,
            password,
          })
        );

        if (result.message === 'Need to validate token') {
          setView('TWOFA');
        } else {
          result.message && setError(result.message);
        }
        if (result.access_token) {
          handleLogIn(result, false);
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.log('API never returns an error', error); // TODO: Ensure API returns an error
      } finally {
        setLoading(false);
      }
    }
  };

  const resetPassword = async (): Promise<void> => {
    setLoading(true);
    if (!isEmail(email)) {
      setLoading(false);
      setError('Invalid email');
    } else {
      try {
        // TODO: Ensure API returns some JSON, we should not be catching errors for success
        await callApi<any>(
          'account/reset-password',
          'POST',
          JSON.stringify({
            email,
          })
        );
        setView('SENT');
      } catch (err: any) {
        setView('SENT');
      } finally {
        setLoading(false);
      }
    }
  };

  const requestInvite = async (): Promise<void> => {
    setLoading(true);
    setError('');
    if (!isEmail(email)) {
      setLoading(false);
      setError('Invalid email');
    } else {
      try {
        setUserState((prevState: any) => ({
          ...prevState,
          data: {
            ...prevState.data,
            name,
          },
        }));
        // TODO: Ensure API returns some valid JSON not just 'true'
        const res = await callApi<any>(
          'invite/dev/request',
          'POST',
          // TODO: This needs tidying up on the API, values are wrong and some meaningless
          JSON.stringify({
            company: website,
            email,
            isTosAccepted: false,
            isTosModalBottomReached: true,
            name,
            usage: info,
          })
        );
        res?.message ? setError('Already registered') : setView('INVITED');
      } catch (err: any) {
        // TODO: get API to return json and error code
        setView('INVITED');
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (userState?.data?.requestInvite) {
      setIsOpen(true);
      setTimeout(() => {
        setView('INVITE');
      }, 50);
    }
  }, [userState?.data?.requestInvite]);

  useEffect(() => {
    if (email.length && view === 'FORGOT') {
      setDisabled(false);
    }
  }, [view]);

  useEffect(() => {
    setError('');
    if (view === 'INVITE')
      name && email && website && info ? setDisabled(false) : setDisabled(true);
    if (view === 'SIGN')
      email && password ? setDisabled(false) : setDisabled(true);
    if (view === 'FORGOT') email ? setDisabled(false) : setDisabled(true);
  }, [email, password, name, website, info, view]);

  useEffect(() => {
    if (isOpen) {
      setView('SIGN');
      setInfo('');
      setPassword('');
      setWebsite('');
    } else {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          requestInvite: false,
        },
      }));
    }
  }, [isOpen]);

  return (
    <>
      <button className='SignIn--button' onClick={(): void => setIsOpen(true)}>
        Sign in
      </button>

      <SideMenu isOpen={isOpen} setIsOpen={setIsOpen} position='CENTER'>
        <div className='SignIn--modal'>
          {view === 'SIGN' && (
            <>
              <Heading
                title='Sign In'
                subtitle={`Welcome back to dStor! ${
                  userState.data?.name || ''
                } `}
              />
              <ServerError error={error} />
              <TextInput
                type='email'
                name='Email'
                value={email}
                placeHolder='Your email address'
                setValue={setEmail}
                focus
              />
              <Password
                name='Password'
                value={password}
                placeHolder='Password'
                setValue={setPassword}
              />
              <Button
                name='Sign In'
                click={signIn}
                loading={loading}
                disabled={disabled}
              />
              <p className='SignIn--invite'>
                Not a member yet?{' '}
                <span onClick={(): void => setView('INVITE')}>
                  Request an invite!
                </span>
              </p>
              <p
                className='SignIn--forgot'
                onClick={(): void => setView('FORGOT')}
              >
                Forgot your password?
              </p>
            </>
          )}
          {view === 'TWOFA' && (
            <>
              <Heading
                title='Two Factor Authentication'
                subtitle='Enter the code from your authenticator app'
              />
              <TextInput
                type='number'
                name='token'
                value={token}
                setValue={setToken}
                focus
              />
              <Button
                name='Verify 2FA'
                click={handle2Fa}
                loading={loading2Fa}
              />
            </>
          )}
          {view === 'SENT' && (
            <>
              <Heading
                title='Email Sent!'
                subtitle='A reset link has been emailed if an account exists.'
              />
              <Button
                name='Sign In'
                click={(): void => setView('SIGN')}
                loading={loading}
              />
            </>
          )}
          {view === 'INVITED' && (
            <>
              <Heading
                title='Request Received!'
                subtitle='Your request is now in our invitation queue, please check your email periodically.'
              />
              <Button
                name='Close'
                click={(): void => setIsOpen(false)}
                loading={loading}
              />
            </>
          )}
          {view === 'FORGOT' && (
            <>
              <Heading
                title='Reset Password'
                subtitle='We will email you a password reset link.'
              />
              <ServerError error={error} />
              <TextInput
                type='email'
                name='Email'
                value={email}
                placeHolder='Your email address'
                setValue={setEmail}
                focus
              />
              <Button
                name='Send'
                click={resetPassword}
                loading={loading}
                disabled={disabled}
              />

              <p
                className='SignIn--forgot'
                onClick={(): void => setView('SIGN')}
              >
                Remembered your password?
              </p>
            </>
          )}
          {view === 'INVITE' && (
            <>
              <Heading
                title='Request Invite'
                subtitle='Fill out the following fields to request your dStor invite'
              />
              <ServerError error={error} />
              <TextInput
                name='Name'
                value={name}
                placeHolder='Your full name'
                setValue={setName}
                focus
                label
              />
              <TextInput
                type='email'
                name='Email'
                value={email}
                placeHolder='Email address'
                setValue={setEmail}
                label
              />
              <TextInput
                name='Website'
                value={website}
                placeHolder='www.'
                setValue={setWebsite}
                label
              />
              <TextInput
                name='Storage Needs'
                value={info}
                setValue={setInfo}
                description='Tell us briefly about your storage needs'
                label
              />
              <Button
                name='Request'
                click={requestInvite}
                loading={loading}
                disabled={disabled}
              />

              <p
                className='SignIn--forgot'
                onClick={(): void => setView('SIGN')}
              >
                I have an account already
              </p>
            </>
          )}
        </div>
      </SideMenu>
    </>
  );
};
export { SignIn };
