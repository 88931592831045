import { ReactElement } from 'react';

export interface PropsShape {
  title: string;
  subtitle?: string;
}

const Heading = ({ title, subtitle }: PropsShape): ReactElement => {
  return (
    <div className='Heading'>
      <h2 className='Heading--title'>{title}</h2>
      {subtitle && <p className='Heading--subtitle'>{subtitle}</p>}
    </div>
  );
};
export { Heading };
