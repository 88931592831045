import { ReactElement, useState, useEffect } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { Menu } from '../../components/Menu';
import { SettingsItem } from '../../components/SettingsItem';
import { Card } from '../../components/Card';
import { CheckBox } from '../../components/CheckBox';
import { callApi } from '../../functions/callApi';

function Notifications(): ReactElement {
  const { userState, setUserState } = useGlobalUserState();
  const [accountDeletedMail] = useState<boolean>(
    userState.data?.pulse?.accountDeletedMail || false
  );
  const [accountDeletedPulse] = useState<boolean>(
    userState.data?.pulse?.accountDeletedPulse || false
  );
  const [accountFrozenMail] = useState<boolean>(
    userState.data?.pulse?.accountFrozenMail || false
  );
  const [accountFrozenPulse] = useState<boolean>(
    userState.data?.pulse?.accountFrozenPulse || false
  );
  const [failedPaymentMail] = useState<boolean>(
    userState.data?.pulse?.failedPaymentMail || false
  );
  const [failedPaymentPulse] = useState<boolean>(
    userState.data?.pulse?.failedPaymentPulse || false
  );
  const [invoiceGeneratedMail, setInvoiceGeneratedMail] = useState<boolean>(
    userState.data?.pulse?.invoiceGeneratedMail || false
  );
  const [invoiceGeneratedPulse, setInvoiceGeneratedPulse] = useState<boolean>(
    userState.data?.pulse?.invoiceGeneratedPulse || false
  );
  const [noPaymentMethodMail, setNoPaymentMethodMail] = useState<boolean>(
    userState.data?.pulse?.noPaymentMethodMail || false
  );
  const [noPaymentMethodPulse, setNoPaymentMethodPulse] = useState<boolean>(
    userState.data?.pulse?.noPaymentMethodPulse || false
  );
  const [successfulPaymentMail] = useState<boolean>(
    userState.data?.pulse?.successfulPaymentMail || false
  );
  const [successfulPaymentPulse] = useState<boolean>(
    userState.data?.pulse?.successfulPaymentPulse || false
  );
  const [thresholdNotReachedMail] = useState<boolean>(
    userState.data?.pulse?.thresholdNotReachedMail || false
  );
  const [thresholdNotReachedPulse] = useState<boolean>(
    userState.data?.pulse?.thresholdNotReachedPulse || false
  );

  useEffect(() => {
    const doUpdate = async (): Promise<void> => {
      const response = await callApi<any>(
        `account/update-properties`,
        'POST',
        JSON.stringify({
          account_key: userState?.data?.accountKey,
          changedSettings: {
            account_deleted_mail: accountDeletedMail,
            account_deleted_pulse: accountDeletedPulse,
            account_frozen_mail: accountFrozenMail,
            account_frozen_pulse: accountFrozenPulse,
            failed_payment_mail: failedPaymentMail,
            failed_payment_pulse: failedPaymentPulse,
            invoice_generated_mail: invoiceGeneratedMail,
            invoice_generated_pulse: invoiceGeneratedPulse,
            no_payment_method_mail: noPaymentMethodMail,
            no_payment_method_pulse: noPaymentMethodPulse,
            successful_payment_mail: successfulPaymentMail,
            successful_payment_pulse: successfulPaymentPulse,
            threshold_not_reached_mail: thresholdNotReachedMail,
            threshold_not_reached_pulse: thresholdNotReachedPulse,
          },
        })
      );
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          pulse: {
            accountDeletedMail: response.account_deleted_mail,
            accountDeletedPulse: response.account_deleted_pulse,
            accountFrozenMail: response.account_frozen_mail,
            accountFrozenPulse: response.account_frozen_pulse,
            failedPaymentMail: response.failed_payment_mail,
            failedPaymentPulse: response.failed_payment_pulse,
            invoiceGeneratedMail: response.invoice_generated_mail,
            invoiceGeneratedPulse: response.invoice_generated_pulse,
            noPaymentMethodMail: response.no_payment_method_mail,
            noPaymentMethodPulse: response.no_payment_method_pulse,
            successfulPaymentMail: response.successful_payment_mail,
            successfulPaymentPulse: response.successful_payment_pulse,
            thresholdNotReachedMail: response.threshold_not_reached_mail,
            thresholdNotReachedPulse: response.threshold_not_reached_pulse,
          },
        },
      }));
    };
    doUpdate();
  }, [
    accountDeletedMail,
    accountDeletedPulse,
    accountFrozenMail,
    accountFrozenPulse,
    failedPaymentMail,
    failedPaymentPulse,
    invoiceGeneratedMail,
    invoiceGeneratedPulse,
    noPaymentMethodMail,
    noPaymentMethodPulse,
    successfulPaymentMail,
    successfulPaymentPulse,
    thresholdNotReachedMail,
    thresholdNotReachedPulse,
  ]);

  const calcNav = (): any[] => {
    const navArr = [
      { name: 'Security', path: '/account/security' },
      { name: 'Notifications', path: '/account/notifications' },
      { name: 'Settings', path: '/account/settings' },
      { name: 'API Keys', path: '/account/api-keys' },
    ];
    if (
      userState.data?.userRole === 'SUPERADMIN' ||
      userState.data?.userRole === 'OUTPOST_HANDLER' ||
      userState.data?.isNodeOperator
    ) {
      navArr.push({ name: 'Storage Nodes', path: '/account/nodes' });
    }
    userState.data?.userRole === 'SUPERADMIN' &&
      navArr.push({ name: 'Users', path: '/account/users' });
    userState.data?.userRole === 'ADMIN' &&
      navArr.push({ name: 'Users', path: '/account/users' });
    return navArr;
  };

  return (
    <div className='ApiKeys'>
      <Header />
      <div className='ApiKeys--center'>
        <div className='ApiKeys--title'>Account</div>
        <div className='ApiKeys--grid'>
          <div>
            <Menu items={calcNav()} selected='Notifications' />
          </div>
          <Card>
            <SettingsItem
              title='Configure'
              subtitle='Where would you like to receive your notifications?'
            >
              <Card>
                {/* <SettingsItem
                  inner
                  title='Failed payment'
                  subtitle='We have tried to take payment but it failed'
                  border
                >
                  <CheckBox
                    label='Mail'
                    id='failedPaymentMail'
                    value={failedPaymentMail}
                    setValue={setFailedPaymentMail}
                  />
                  <CheckBox
                    label='Pulse'
                    id='failedPaymentPulse'
                    value={failedPaymentPulse}
                    setValue={setFailedPaymentPulse}
                  />
                </SettingsItem> */}
                {/* <SettingsItem
                  inner
                  title='Successful payment'
                  subtitle='We successfully took a payment'
                  border
                >
                  <CheckBox
                    label='Mail'
                    id='successfulPaymentMail'
                    value={successfulPaymentMail}
                    setValue={setSuccessfulPaymentMail}
                  />
                  <CheckBox
                    label='Pulse'
                    id='successfulPaymentPulse'
                    value={successfulPaymentPulse}
                    setValue={setSuccessfulPaymentPulse}
                  />
                </SettingsItem> */}
                <SettingsItem
                  inner
                  title='No payment method'
                  subtitle="We have noticed that you don't have a payment method"
                  border
                >
                  <CheckBox
                    label='Mail'
                    id='noPaymentMethodMail'
                    value={noPaymentMethodMail}
                    setValue={setNoPaymentMethodMail}
                  />
                  <CheckBox
                    label='Pulse'
                    id='noPaymentMethodPulse'
                    value={noPaymentMethodPulse}
                    setValue={setNoPaymentMethodPulse}
                  />
                </SettingsItem>
                {/* <SettingsItem
                  inner
                  title='Threshold not reached'
                  subtitle='Your usage for the last period was below out billable threshold'
                  border
                >
                  <CheckBox
                    label='Mail'
                    id='thresholdNotReachedMail'
                    value={thresholdNotReachedMail}
                    setValue={setThresholdNotReachedMail}
                  />
                  <CheckBox
                    label='Pulse'
                    id='thresholdNotReachedPulse'
                    value={thresholdNotReachedPulse}
                    setValue={setThresholdNotReachedPulse}
                  />
                </SettingsItem> */}
                <SettingsItem
                  inner
                  title='Invoice generated'
                  subtitle='We have created an invoice for you'
                  border
                >
                  <CheckBox
                    label='Mail'
                    id='invoiceGeneratedMail'
                    value={invoiceGeneratedMail}
                    setValue={setInvoiceGeneratedMail}
                  />
                  <CheckBox
                    label='Pulse'
                    id='invoiceGeneratedPulse'
                    value={invoiceGeneratedPulse}
                    setValue={setInvoiceGeneratedPulse}
                  />
                </SettingsItem>
                {/* <SettingsItem
                  inner
                  title='Account Frozen'
                  subtitle='We have frozen your account'
                  border
                >
                  <CheckBox
                    label='Mail'
                    id='accountFrozenMail'
                    value={accountFrozenMail}
                    setValue={setAccountFrozenMail}
                  />
                  <CheckBox
                    label='Pulse'
                    id='accountFrozenPulse'
                    value={accountFrozenPulse}
                    setValue={setAccountFrozenPulse}
                  />
                </SettingsItem> */}
                {/* <SettingsItem
                  inner
                  title='Account Deleted'
                  subtitle='We have deleted your account'
                >
                  <CheckBox
                    label='Mail'
                    id='accountDeletedMail'
                    value={accountDeletedMail}
                    setValue={setAccountDeletedMail}
                  />
                  <CheckBox
                    label='Pulse'
                    id='accountDeletedPulse'
                    value={accountDeletedPulse}
                    setValue={setAccountDeletedPulse}
                  />
                </SettingsItem> */}
              </Card>
            </SettingsItem>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
