import React, { createContext, useCallback } from 'react';

interface MessageData {
  message: string;
  messageType: string;
}

type SendMessageFunction = (messageData: MessageData) => Promise<void>;

interface SendMessageContextValue {
  sendMessage: SendMessageFunction;
}

const SendMessageContext = createContext<SendMessageContextValue>({
  sendMessage: async () => {},
});

const useSendMessage = (token: string): SendMessageFunction => {
  const sendMessage = useCallback(
    ({ message, messageType }: MessageData): Promise<void> => {
      const options = {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: message,
          messageType: messageType,
        }),
      };
      return fetch('https://live.testnet.dstor.cloud/messages', options).then(
        (response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
        }
      );
    },
    [token]
  );

  return sendMessage;
};

interface SendMessageProviderProps {
  children: React.ReactNode;
  token: string;
}

const SendMessageProvider = ({
  children,
  token,
}: SendMessageProviderProps): JSX.Element => {
  const sendMessage = useSendMessage(token);
  const sendMessageWithToken = useCallback(
    (messageData: MessageData) => sendMessage(messageData),
    [sendMessage]
  );

  const value: SendMessageContextValue = {
    sendMessage: sendMessageWithToken,
  };

  return (
    <SendMessageContext.Provider value={value}>
      {children}
    </SendMessageContext.Provider>
  );
};

export { SendMessageProvider, SendMessageContext };
