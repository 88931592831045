import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { Menu } from '../../components/Menu';
import { SettingsItem } from '../../components/SettingsItem';
import { Card } from '../../components/Card';
import { callApi } from '../../functions/callApi';
import { Button } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { ServerError } from '../../components/ServerError';
import { SideDataGrid } from '../../components/SideDataGrid';
import { SideDataGridItem } from '../../components/SideDataGridItem';
import { isEmail, isIpAddress } from '../../functions/validators';
import { Copy } from '../../components/Copy';
import { DateTime } from '../../components/DateTime';
import { epochToDate } from '../../functions/formatters';
import { SelectInput } from '../../components/SelectInput';

import online from '../../images/online.svg';
import offline from '../../images/offline.svg';
import enabled from '../../images/enabled.svg';
import disabled from '../../images/disabled.svg';
import healthy from '../../images/healthy.svg';
import unhealthy from '../../images/unhealthy.svg';

function Nodes(): ReactElement {
  const { userState, setUserState } = useGlobalUserState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenRight, setIsOpenRight] = useState<boolean>(false);
  const [isOpenKey, setIsOpenKey] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [ipAddress, setIpAddress] = useState<string>('');
  const [connectionSpeed, setConnectionSpeed] = useState<string>('');
  const [storageCapacity, setStorageCapacity] = useState<string>('');
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [addDisabled, setAddDisabled] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [selectedNode, setSelectedNode] = useState<any>();
  const [disableLoading, setDisableLoading] = useState<boolean>(false);
  const [offlineLoading, setOfflineLoading] = useState<boolean>(false);
  const [deleteNodeLoading, setDeleteNodeLoading] = useState<boolean>(false);

  const [addNodeId, setAddNodeId] = useState<string>('');
  const [addExpiration, setAddExpiration] = useState<string>('');
  const [addComment, setAddComment] = useState<string>('');
  const [addApiDisabled, setAddApiDisabled] = useState<boolean>(false);

  useEffect(() => {
    setAddDisabled(
      !email || !ipAddress || !connectionSpeed || !storageCapacity
    );
  }, [email, ipAddress, connectionSpeed, storageCapacity]);

  const doUpdateNodes = async (): Promise<void> => {
    let url: string = '';
    if (userState.data?.userRole === 'SUPERADMIN') {
      url = `op/info`;
    } else {
      url = `op/info/${userState.data?.accountKey}`;
    }
    const response = await callApi<any>(url);
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        storageNodes: response,
      },
    }));
  };

  const doUpdateKeys = async (): Promise<void> => {
    const url = `op/api-keys`;
    const response = await callApi<any>(url);
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        apiKeys: response,
      },
    }));
  };

  useEffect(() => {
    doUpdateNodes();
    doUpdateKeys();
  }, []);

  useEffect(() => {
    if (addComment && addNodeId && addExpiration) {
      setAddApiDisabled(false);
    } else {
      setAddApiDisabled(true);
    }
  }, [addComment, addNodeId, addExpiration]);

  const addApiKey = async (): Promise<void> => {
    const body = JSON.stringify({
      comment: addComment,
      outpost_key: addNodeId,
      expiration: addExpiration,
    });
    await callApi<any>(`op/api-key`, 'POST', body);
    await doUpdateKeys();
    setIsOpenKey(false);
  };

  const addNode = async (): Promise<void> => {
    try {
      setServerError('');
      if (!isEmail(email)) {
        setServerError('Invalid email address');
        return;
      } else if (!isIpAddress(ipAddress)) {
        setServerError('Invalid IP address');
        return;
      }
      setAddLoading(true);
      const emptyBody = JSON.stringify('');
      await callApi<any>(`op/add`, 'POST', emptyBody);
      const body = JSON.stringify({
        isTosAccepted: false,
        isTosModalBottomReached: true,
        opData: {
          bandwidth_avail: connectionSpeed,
          email,
          public_v4: ipAddress,
          storage_avail: storageCapacity,
        },
      });
      const response = await callApi<any>(`op/save-info`, 'POST', body);
      if (response.account_key) {
        setIsOpen(false);
        doUpdateNodes();
      }
      response.status === 400 &&
        setServerError(response.message || 'Something went wrong A.');
    } catch (error: any) {
      if (error != 'SyntaxError: Unexpected end of JSON input') {
        setServerError(error.message || 'Something went wrong B.');
      }
    } finally {
      setAddLoading(false);
    }
  };

  const deleteApiKey = async (key: string): Promise<void> => {
    try {
      const body = JSON.stringify({
        api_key: key,
        comment: 'Delete me!',
      });
      await callApi<any>(`op/api-key/${key}`, 'DELETE', body);
      doUpdateKeys();
    } catch (error) {
      setIsOpen(false);
    }
  };
  const disableNode = async (): Promise<void> => {
    try {
      setDisableLoading(true);
      const body = JSON.stringify({
        account_key: userState.data?.accountKey,
        outpost_key: selectedNode.outpost_key,
        data: { is_enabled: true },
      });
      await callApi<any>(`op`, 'PATCH', body);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setDisableLoading(false);
      setIsOpenRight(false);
      doUpdateNodes();
    }
  };

  const offlineNode = async (): Promise<void> => {
    try {
      setOfflineLoading(true);
      const body = JSON.stringify({
        account_key: userState.data?.accountKey,
        outpost_key: selectedNode.outpost_key,
        data: { is_online: false },
      });
      await callApi<any>(`op`, 'PATCH', body);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setOfflineLoading(false);
      setIsOpenRight(false);
      doUpdateNodes();
    }
  };

  const deleteNode = async (): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('deleteNode');
    try {
      setDeleteNodeLoading(true);
      const body = JSON.stringify({
        outpost_key: selectedNode.outpost_key,
        changedSettings: { is_deleted: true },
      });
      await callApi<any>(`op/delete`, 'POST', body);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setDeleteNodeLoading(false);
      setIsOpenRight(false);
      doUpdateNodes();
    }
  };

  const calcNav = (): any[] => {
    const navArr = [
      { name: 'Security', path: '/account/security' },
      { name: 'Notifications', path: '/account/notifications' },
      { name: 'Settings', path: '/account/settings' },
      { name: 'API Keys', path: '/account/api-keys' },
    ];
    if (
      userState.data?.userRole === 'SUPERADMIN' ||
      userState.data?.userRole === 'OUTPOST_HANDLER' ||
      userState.data?.isNodeOperator
    ) {
      navArr.push({ name: 'Storage Nodes', path: '/account/nodes' });
    }
    userState.data?.userRole === 'SUPERADMIN' &&
      navArr.push({ name: 'Users', path: '/account/users' });
    userState.data?.userRole === 'ADMIN' &&
      navArr.push({ name: 'Users', path: '/account/users' });
    return navArr;
  };

  const calcNodeList = (): any => {
    if (userState?.data?.storageNodes) {
      const nodes = userState?.data?.storageNodes;
      const foldersArr = [];
      for (let i = 0; i < nodes.length; i++) {
        foldersArr.push({
          value: `${nodes[i].outpost_key}`,
          name: `${nodes[i].hostname} - ${nodes[i].outpost_key}`,
        });
      }

      return foldersArr;
    }
  };

  return (
    <div className='ApiKeys'>
      <Header />
      <div className='ApiKeys--center'>
        <div className='ApiKeys--title'>Account</div>
        <SideMenu
          isOpen={isOpenKey}
          setIsOpen={setIsOpenKey}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title='Add API Key'
              subtitle='Add a new API key to a Storage Node.'
            />
            <ServerError error={serverError} />

            <SelectInput
              name='Select Node'
              value={addNodeId}
              setValue={setAddNodeId}
              options={calcNodeList()}
            />
            <TextInput
              type='number'
              name='Expiration (hours)'
              value={addExpiration}
              setValue={setAddExpiration}
              label
            />
            <TextInput
              type='text'
              name='Comment'
              value={addComment}
              setValue={setAddComment}
              description='Used to identify this node.'
              label
            />
            <Button
              name='Add'
              click={addApiKey}
              loading={addLoading}
              disabled={addApiDisabled}
            />
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading title='Add Node' subtitle='Add a new Storage Node.' />
            <ServerError error={serverError} />
            <TextInput
              type='text'
              name='Email'
              value={email}
              setValue={setEmail}
              description='Used to notify you of any issues with your node.'
              focus
              label
            />

            <TextInput
              type='text'
              name='IP Address'
              value={ipAddress}
              setValue={setIpAddress}
              placeHolder='192.168.1.1'
              label
            />
            <TextInput
              type='number'
              name='Connection Speed (Mbps)'
              value={connectionSpeed}
              setValue={setConnectionSpeed}
              label
            />
            <TextInput
              type='number'
              name='Storage Capacity (TB)'
              value={storageCapacity}
              setValue={setStorageCapacity}
              label
            />

            <Button
              name='Add'
              click={addNode}
              loading={addLoading}
              disabled={addDisabled}
            />
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isOpenRight}
          setIsOpen={setIsOpenRight}
          position='RIGHT'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title={selectedNode?.hostname}
              subtitle='You are editing this nodes settings.'
            />
            <div>
              <br></br>
              <br></br>
              <>
                <SideDataGrid description='Info'>
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Public v4: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.public_v4}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Account Key: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.account_key}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Outpost Key: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.outpost_key}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Storage Available ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.storage_avail}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Connection Speed (Mbps):',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.bandwidth_avail}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Bandwidth Used: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.bandwidth_used || 0}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />

                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Created: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${epochToDate(selectedNode?.created, true)}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Free Fslots: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.free_fslots}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />

                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Server Load: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.server_load}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                </SideDataGrid>

                <SideDataGrid description='Status'>
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Deleted: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.is_deleted}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Enabled: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.is_enabled}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Healthy: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.is_healthy}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Online: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.is_online}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Last Seen: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${epochToDate(
                            selectedNode?.last_seen,
                            true
                          )}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                </SideDataGrid>
                <SideDataGrid description='Location'>
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'City: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.city}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Continent: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.continent}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Country: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.country}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />

                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Latitude: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.latitude}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                  <SideDataGridItem
                    data={{
                      display: [
                        {
                          value: 'Longitude: ',
                          weight: 'normal',
                          align: 'right',
                          width: '1fr',
                        },
                        {
                          value: `${selectedNode?.longitude}`,
                          weight: 'bold',
                          align: 'left',
                          width: '1fr',
                        },
                      ],
                    }}
                  />
                </SideDataGrid>

                <Button
                  name='Disable Node'
                  click={disableNode}
                  loading={disableLoading}
                />
                <Button
                  name='Take node offline'
                  click={offlineNode}
                  loading={offlineLoading}
                />

                <Button
                  name='Delete Node'
                  click={deleteNode}
                  loading={deleteNodeLoading}
                  color='RED'
                />
              </>
            </div>
          </div>
        </SideMenu>
        <div className='ApiKeys--grid'>
          <div>
            <Menu items={calcNav()} selected='My Storage Nodes' />
          </div>
          <Card>
            <SettingsItem
              title='Nodes'
              subtitle='You can add and remove nodes from your account'
              button='Add Node'
              buttonClick={(): void => setIsOpen(true)}
            >
              <Card>
                <>
                  {userState?.data?.storageNodes?.length &&
                    userState?.data?.storageNodes?.map(
                      (node: any, index: any) => (
                        <div key={index} style={{ cursor: 'pointer' }}>
                          <SettingsItem
                            inner
                            title={
                              `${node.hostname || 'Error'} ${
                                node.country || ''
                              } @ ${node.public_v4 || ''}` || ''
                            }
                            subtitle={
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: 'auto auto auto auto',
                                  marginTop: '12px',
                                  maxWidth: '200px',
                                }}
                              >
                                <span>
                                  {node.is_enabled ? (
                                    <img src={enabled} />
                                  ) : (
                                    <img src={disabled} />
                                  )}
                                </span>
                                <span>
                                  {node.is_healthy ? (
                                    <img src={healthy} />
                                  ) : (
                                    <img src={unhealthy} />
                                  )}
                                </span>
                                <span>
                                  {node.is_online ? (
                                    <img src={online} />
                                  ) : (
                                    <img src={offline} />
                                  )}
                                </span>
                              </div>
                            }
                            border
                          >
                            <Button
                              name='Inspect'
                              click={(): any => {
                                setIsOpenRight(true);
                                setSelectedNode(node);
                              }}
                            />
                          </SettingsItem>
                        </div>
                      )
                    )}
                </>
              </Card>
            </SettingsItem>
            <SettingsItem
              title='Node API keys'
              subtitle='You can add and remove nodes from your account'
              button='Create API Key'
              buttonClick={(): void => setIsOpenKey(true)}
            >
              <Card>
                <div>
                  {userState?.data?.apiKeys?.length !== 0 &&
                    userState?.data?.apiKeys?.map((key, index) => (
                      <div key={index}>
                        <SettingsItem
                          inner
                          title={
                            <>
                              <Copy
                                value={key.api_key}
                                type={'BUTTON'}
                                text={key.api_key}
                              />
                            </>
                          }
                          subtitle={
                            <div>
                              <DateTime
                                showTime
                                value={new Date(key.created)}
                              />{' '}
                              '{key.comment}'
                            </div>
                          }
                          border
                        >
                          <Button
                            name='Delete'
                            margin={0}
                            click={(): void => {
                              // setIsDeleteOpen(true);
                              deleteApiKey(key.api_key);
                            }}
                          />
                        </SettingsItem>
                      </div>
                    ))}
                  {!userState.data?.apiKeys ||
                    (userState.data?.apiKeys.length === 0 && (
                      <div style={{ padding: '12px', textAlign: 'center' }}>
                        No Api Keys created, please tap "Add Key"
                      </div>
                    ))}
                </div>
              </Card>
            </SettingsItem>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Nodes;
