import { ReactElement, useState, useEffect } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { Menu } from '../../components/Menu';
import { SettingsItem } from '../../components/SettingsItem';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { callApi } from '../../functions/callApi';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { QRCodeSVG } from 'qrcode.react';
import ChangePasswordLoggedIn from '../../components/ChangePasswordLoggedIn';

function Security(): ReactElement {
  const { userState, setUserState } = useGlobalUserState();
  const [resetCodeLoading, setResetCodeLoading] = useState<boolean>(false);
  const [isOpenTwoFA, setIsOpenTwoFA] = useState<boolean>(false);
  const [isOpenChangePassword, setIsOpenChangePassword] =
    useState<boolean>(false);
  const [loading2Fa, setLoading2Fa] = useState<boolean>(false);
  const [secret2Fa, setSecret2Fa] = useState<boolean>(false);
  const [device, setDevice] = useState<'Android' | 'iOS' | 'Unknown'>(
    'Unknown'
  );

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || '';

    if (/android/i.test(userAgent)) {
      setDevice('Android');
    } else if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !/windows phone/i.test(userAgent)
    ) {
      setDevice('iOS');
    } else {
      setDevice('Unknown');
    }
  }, []);

  const renderDownloadLink = (): any => {
    if (device === 'Android') {
      return (
        <a
          href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
          target='_blank'
          rel='noopener noreferrer'
        >
          Download Google Authenticator for Android
        </a>
      );
    } else if (device === 'iOS') {
      return (
        <a
          href='https://apps.apple.com/app/google-authenticator/id388497605'
          target='_blank'
          rel='noopener noreferrer'
        >
          Download Google Authenticator for iOS
        </a>
      );
    } else {
      return;
    }
  };

  const calcNav = (): any[] => {
    const navArr = [
      { name: 'Security', path: '/account/security' },
      { name: 'Notifications', path: '/account/notifications' },
      { name: 'Settings', path: '/account/settings' },
      { name: 'API Keys', path: '/account/api-keys' },
    ];
    if (
      userState.data?.userRole === 'SUPERADMIN' ||
      userState.data?.userRole === 'OUTPOST_HANDLER' ||
      userState.data?.isNodeOperator
    ) {
      navArr.push({ name: 'Storage Nodes', path: '/account/nodes' });
    }
    userState.data?.userRole === 'SUPERADMIN' &&
      navArr.push({ name: 'Users', path: '/account/users' });
    userState.data?.userRole === 'ADMIN' &&
      navArr.push({ name: 'Users', path: '/account/users' });
    return navArr;
  };
  const sendResetCodeEmail = async (): Promise<void> => {
    setResetCodeLoading(true);
    await callApi<any>(
      `account/reset-password`,
      'POST',
      JSON.stringify({ email: userState?.data?.email })
    );
    setResetCodeLoading(false);
  };

  const handle2fa = async (): Promise<void> => {
    setLoading2Fa(true);

    const res = await callApi<any>(
      `auth/toggle-2fa `,
      'POST',
      JSON.stringify({
        is_enable_2fa: !userState.data?.enabled2Fa,
      })
    );
    if (res.secret) {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          enabled2Fa: true,
        },
      }));
      setSecret2Fa(res.secret);
      setIsOpenTwoFA(true);
    } else {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          enabled2Fa: false,
        },
      }));
    }
    setLoading2Fa(false);
  };

  return (
    <div className='ApiKeys'>
      <Header />
      <div className='ApiKeys--center'>
        <div className='ApiKeys--title'>Account</div>
        <div className='ApiKeys--grid'>
          <div>
            <Menu items={calcNav()} selected='Security' />
          </div>

          <Card>
            <SettingsItem title='Password' subtitle='Change your password'>
              <Card>
                <SettingsItem
                  inner
                  title='Open a popup'
                  subtitle='We will display a popup for changing the password'
                >
                  <Button
                    name='Open'
                    margin={0}
                    click={(): void => setIsOpenChangePassword(true)}
                  />
                </SettingsItem>
              </Card>
            </SettingsItem>
            <SettingsItem title='Password' subtitle='Reset your password'>
              <Card>
                <SettingsItem
                  inner
                  title='Request Reset Code'
                  subtitle='We will send you an email with a reset code'
                >
                  <Button
                    name='Send'
                    margin={0}
                    click={sendResetCodeEmail}
                    loading={resetCodeLoading}
                  />
                </SettingsItem>
              </Card>
            </SettingsItem>
            <SettingsItem title='2FA' subtitle='Secure your account'>
              <Card>
                <SettingsItem
                  inner
                  title='Google Authenticator'
                  subtitle='Enable 2FA with Google Authenticator'
                >
                  <Button
                    name={userState.data?.enabled2Fa ? 'Disable' : 'Enable'}
                    margin={0}
                    click={handle2fa}
                    loading={loading2Fa}
                  />
                </SettingsItem>
              </Card>
            </SettingsItem>
          </Card>
        </div>
      </div>
      <SideMenu
        isOpen={isOpenTwoFA}
        setIsOpen={setIsOpenTwoFA}
        position='CENTER'
        width='REGULAR'
      >
        <div style={{ padding: '24px' }}>
          <Heading
            title='Setup 2FA'
            subtitle='Scan the QR code below with Google Authenticator'
          />
          <div style={{ textAlign: 'center' }}>
            <QRCodeSVG
              value={`otpauth://totp/${userState?.data?.name}?secret=${secret2Fa}&issuer=dStor&algorithm=SHA1&digits=6&period=30
`}
            />
            <p style={{ paddingTop: '24px' }}>
              Or enter the setup key manually{' '}
              <span style={{ fontSize: '11px', fontWeight: 'bold' }}>
                {secret2Fa}{' '}
              </span>
            </p>
            <p> {renderDownloadLink()}</p>
          </div>
        </div>
      </SideMenu>
      <SideMenu
        isOpen={isOpenChangePassword}
        setIsOpen={setIsOpenChangePassword}
        position='CENTER'
        width='REGULAR'
      >
        <ChangePasswordLoggedIn setIsOpen={setIsOpenChangePassword} />
      </SideMenu>
    </div>
  );
}

export default Security;
