import { ReactElement, useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { callApi } from '../functions/callApi';
import { DateTime } from '../components/DateTime';

export interface PulseShape {
  created_at: number;
  id: number;
  is_read: boolean;
  link: string;
  message: string;
  title: string;
}
const Pulse = (): ReactElement => {
  const menuTransition = useRef<null | HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<PulseShape[]>();

  const getPulse = async (): Promise<void> => {
    const messages = await callApi<any>(
      'account/notifications?status=all',
      'GET'
    );
    if (!messages.error) {
      setNotifications(messages);
    }
  };
  useEffect(() => {
    if (isOpen) {
      const getMessages = async (): Promise<void> => {
        if (notifications && notifications.length) {
          const notRead = notifications?.filter((msg) => msg.is_read === false);
          const ids: number[] = [];
          notRead?.forEach((e: PulseShape) => {
            ids.push(e.id);
          });
          await callApi<any>(
            'account/notifications-batch',
            'PUT',
            JSON.stringify({
              ids,
              data: {
                is_read: true,
              },
            })
          );
          getPulse();
        }
      };
      getMessages();
    }
  }, [isOpen]);
  const countUnread = (): number => {
    if (notifications && notifications.length) {
      const notRead = notifications?.filter((msg) => msg.is_read === false);
      return notRead?.length || 0;
    }
    return 0;
  };
  useEffect(() => {
    const loggedIn = JSON.parse(`${localStorage.getItem('userState')}`).data
      ?.loggedIn;
    if (loggedIn) {
      getPulse();
    }
  }, []);
  return (
    <div className='Pulse'>
      {isOpen && (
        <div
          className='Pulse--overlay'
          onClick={(): void => setIsOpen(!isOpen)}
        ></div>
      )}
      <div className='Pulse--button' onClick={(): void => setIsOpen(!isOpen)}>
        <div className='Pulse--avatar' />
        {countUnread() > 0 && (
          <div className='Pulse--count'>{countUnread()}</div>
        )}
      </div>
      <CSSTransition
        nodeRef={menuTransition}
        in={isOpen}
        timeout={300}
        classNames={`Pulse--transition`}
        unmountOnExit
      >
        <div className='Pulse--menu' ref={menuTransition}>
          <div className='Pulse--title'>The pulse</div>
          <div className='Pulse--scroll'>
            {notifications &&
              notifications.length > 0 &&
              notifications?.map((key: PulseShape, index: number) => (
                <a
                  href={key.link}
                  style={{ textDecoration: 'none' }}
                  key={index}
                >
                  <div className='Pulse--item'>
                    <div className='Pulse--subject'>{key.title}</div>
                    <div className='Pulse--subtitle'>{key.message}</div>
                    <div className='Pulse--time'>
                      <DateTime showTime value={new Date(key.created_at)} />
                    </div>
                    <div className='Pulse--subtitle'>{key.message}</div>
                  </div>
                </a>
              ))}
            {notifications && notifications.length == 0 && (
              <div style={{ padding: '12px' }}>You have no notifications</div>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
export { Pulse };
