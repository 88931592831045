import { ReactElement } from 'react';
import { Button } from './Button';

export interface PropsShape {
  children?: ReactElement | ReactElement[] | string;
  title: ReactElement | string;
  subtitle: ReactElement | string;
  button?: string;
  buttonClick?: any;
  inner?: boolean;
  border?: boolean;
}

const SettingsItem = ({
  children,
  title,
  subtitle,
  button,
  buttonClick,
  inner = false,
  border = false,
}: PropsShape): ReactElement => {
  return (
    <div
      className={`SettingsItem--${inner ? 'inner' : 'outer'}`}
      style={{
        borderBottom: border ? '1px solid #e1e1e1' : 'none',
      }}
    >
      <div className='SettingsItem--left'>
        <div className='SettingsItem--left-title'>{title}</div>
        <div className='SettingsItem--left-subtitle'>{subtitle}</div>

        {button && <Button name={button} click={buttonClick} />}
      </div>
      <div className='SettingsItem--right'>{children}</div>
    </div>
  );
};
export { SettingsItem };
