import { ReactElement, Dispatch, SetStateAction } from 'react';

export interface PropsShape {
  label?: boolean;
  type?: string;
  name: string;
  value: string;
  setValue?: Dispatch<SetStateAction<string>>;
  placeHolder?: string;
  error?: string;
  description?: string;
  autoComplete?: boolean;
  focus?: boolean;
  spellCheck?: boolean;
  info?: string;
  optional?: boolean;
  upperCase?: boolean;
  disabled?: boolean;
  size?: 'REGULAR' | 'LARGE';
  mode?: 'LIGHT' | 'DARK';
}

const TextInput = ({
  label = false,
  name,
  type = 'text',
  value,
  setValue,
  placeHolder,
  error,
  description,
  autoComplete = false,
  focus = false,
  spellCheck = false,
  info,
  optional = false,
  upperCase = false,
  disabled = false,
  size = 'REGULAR',
  mode = 'LIGHT',
}: PropsShape): ReactElement => {
  const calcStyle = (): string => {
    let styleStr = 'TextInput--input';
    if (disabled) styleStr += ' TextInput--input-disabled';
    if (size === 'LARGE') styleStr += ' TextInput--input-large';
    return styleStr;
  };
  return (
    <div
      className='TextInput'
      style={{ color: mode === 'LIGHT' ? '#434343' : '#FFF' }}
    >
      {info && <>{info}</>}
      {error && <div className='TextInput--error'>{error}</div>}
      {optional && <div className='TextInput--optional'>Optional</div>}
      {label && (
        <label className='TextInput--label' htmlFor={name}>
          {name}
        </label>
      )}
      <input
        type={type}
        id={name}
        name={name}
        value={upperCase ? value.toUpperCase() : value}
        placeholder={placeHolder}
        onChange={(e): void => {
          if (setValue && !disabled) setValue(e.target.value);
        }}
        className={calcStyle()}
        autoComplete={autoComplete === false ? 'false' : 'true'}
        autoFocus={focus}
        spellCheck={spellCheck}
        disabled={disabled}
      ></input>
      {description && (
        <small className='TextInput--description'>{description}</small>
      )}
    </div>
  );
};
export { TextInput };
