import { ReactElement, useState } from 'react';
import { Header } from '../../components/Header';
import { Title } from '../../components/Title';
import { Footer } from '../../components/Footer';
import { Background } from '../../components/Background';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/Button';
import { callApi } from '../../functions/callApi';
import { PointBox } from '../../components/PointBox';
import { SplitCenter } from '../../components/SplitCenter';
import { TextImage } from '../../components/TextImage';
import { countries } from '../../functions/iso3166-alpha2';
import { SelectInput } from '../../components/SelectInput';
import completeTick from '../../images/complete-green-tick.svg';

function StorageNode(): ReactElement {
  const [email, setEmail] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [storageCapacity, setStorageCapacity] = useState<string>('');
  const [uploadNetworkSpeed, setUploadNetworkSpeed] = useState<string>('');
  const [downloadNetworkSpeed, setDownloadNetworkSpeed] = useState<string>('');
  const [expectedGrowthRate, setExpectedGrowthRate] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  interface ErrorShape {
    message: string;
    type: string;
    path: string;
    value: any;
  }

  const getErrorString = (errors: Array<ErrorShape>): string => {
    return errors.reduce((str, error) => {
      return str ? `${str}\n${error.message}` : `${error.message}`;
    }, '');
  };

  const submit = async (): Promise<void> => {
    try {
      setLoading(true);
      const result = await callApi<any>(
        'invite/op/request',
        'POST',
        JSON.stringify({
          email,
          name,
          country,
          upload_speed: +uploadNetworkSpeed,
          download_speed: +downloadNetworkSpeed,
          storage_avail: +storageCapacity,
          growth_rate: +expectedGrowthRate,
        })
      );
      if (result && result.message && result.status !== 200) {
        setError(result.message);
      } else if (result && result.errors && result.errors.length) {
        const errorString = getErrorString(result.errors);
        setError(errorString);
      } else {
        setComplete(true);
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className='StorageNodes'>
      <Header />
      <Background color='#F8FAFC' borderTop>
        <>
          <Title
            title='Join the network as a storage node operator'
            subTitle='We designed dStor to be easy to use, whether you want to integrate dStor into your current app or to build a whole new service on the dStor storage platform.'
          />
        </>
      </Background>

      <Background color='#FFF'>
        <PointBox
          topColor='#F8FAFC'
          bottomColor='#FFF'
          titleLeft='Benefits'
          tickArrayLeft={[
            'Easy setup',
            'Earn pay ',
            'Low equipment costs',
            'Set your own growth path',
          ]}
          titleCenter='Prerequisites'
          tickArrayCenter={[
            'Fast Broadband connection',
            'Excellent uptime',
            '4 TiB minimum storage',
            'Knowledge of Linux, TCP/IP',
          ]}
          titleRight='Payouts'
          tickArrayRight={[
            'Share in 50% of dStor revenue',
            'Revenue share based on performance',
            'Earn more for consistent service',
            'Increase your pay by adding capacity',
          ]}
        />
      </Background>

      <Background color='#30385E'>
        <Title
          mode='dark'
          title='Register your interest'
          subTitle='We designed dStor to be easy to use, whether you want to integrate dStor into your current app or to build a whole new service on the dStor storage platform.'
        />
        <SplitCenter>
          <div className='StorageNodes--form'>
            {complete && (
              <div className='StorageNodes--complete'>
                <img src={completeTick} />
                <div className='StorageNodes--title'>Success</div>
                <div>Thank you for your interest!</div>
                <div>We have added your submission to the request queue. </div>
              </div>
            )}
            {!complete && (
              <>
                <TextInput
                  mode='DARK'
                  name='Name'
                  value={name}
                  setValue={setName}
                  type='text'
                  label
                />
                <TextInput
                  mode='DARK'
                  name='Email'
                  value={email}
                  setValue={setEmail}
                  type='email'
                  label
                  description='Your email address used with your dStor account'
                />

                <SelectInput
                  label
                  mode='DARK'
                  name='Country'
                  value={country}
                  setValue={setCountry}
                  options={countries()}
                />

                <TextInput
                  mode='DARK'
                  name='Storage Capacity (GB)'
                  value={storageCapacity}
                  setValue={setStorageCapacity}
                  type='number'
                  label
                  description='How much storage space have available for dStor use?'
                />
                <TextInput
                  mode='DARK'
                  name='Upload Network Speed (Mbps)'
                  value={uploadNetworkSpeed}
                  setValue={setUploadNetworkSpeed}
                  type='number'
                  label
                />
                <TextInput
                  mode='DARK'
                  name='Download Network Speed (Mbps)'
                  value={downloadNetworkSpeed}
                  setValue={setDownloadNetworkSpeed}
                  type='number'
                  label
                />
                <TextInput
                  mode='DARK'
                  name='Expected growth rate (GB per annum)'
                  value={expectedGrowthRate}
                  setValue={setExpectedGrowthRate}
                  type='number'
                  label
                />
                <div className='StorageNodes--error'>{error}</div>
                <Button name='Submit' click={submit} loading={loading} />
              </>
            )}
          </div>
          <div>
            <TextImage
              padding='0 0 48px 0'
              mode='dark'
              title='Start Earning with dStor'
              text="Ready to join dStor as a storage node operator? It's easy! Once dStor is out of its beta testing phase, onboarding storage nodes will be automated but for now it's a manual process. To start, please provide the requested information. One of our support team will review your form. If approved, then you will receive further instructions at the registered email. A node operator request meeting the prerequisites above will generally be approved. Responses can take up to two weeks for approval."
            />
            <TextImage
              padding='0 0 48px 0'
              mode='dark'
              title='dStor Incentivization Algorithm'
              text='To continuously improve the quality and capacity of the dStor network, our incentivzation algorithm pays storage nodes based on how much data they actually store and serve, with more gateway requests directed to the highest performance nodes. This encourages storage node operators to maintain high quality, reliable infrastructure in healthy competition with others on the network for maximum pay.'
            />
            <TextImage
              padding='0 0 48px 0'
              mode='dark'
              title='Storage Node Payment'
              text="Following the full dStor release, storage node operators will be paid at the end of each week using the dStor incentivization algorithm for the prior week's performance and using a high performance blockchain as an instant, fee-less payment platform.

While dStor remains in its beta release, storage nodes will be paid monthly roughly six weeks after the beginning of the billing cycle. dStor income is converted into TLOS by the system so that accounts may automatically pay and withdraw. Each provider will be paid directly into their Telos account by smart contract. In the future, we intend to have automated conversion into other stablecoin and cryptocurrencies. For more information, refer to the documentation by clicking the Storage Node Operation button below."
              buttonText='Storage Node Operation'
              buttonLink='https://goodblock.gitbook.io/dstor-documentation-1/storage-node-operation-guide/storage-node-operation'
            />
          </div>
        </SplitCenter>
      </Background>
      <Footer />
      <div className='Pricing--center'></div>
    </div>
  );
}

export default StorageNode;
