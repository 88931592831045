/* eslint-disable no-console */
import React, { ReactElement, useState, useEffect } from 'react';
import logo from '../images/dStor.svg';
import { SignIn } from '../components/SignIn';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { UserMenu } from './UserMenu';
import { Pulse } from './Pulse';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import SideMenu from './SideMenu';
import { Heading } from './Heading';
import { Button } from './Button';
import { TextInput } from './TextInput';
import { ServerError } from './ServerError';
import { callApi, callApiUpload, callApiUploadXhr } from '../functions/callApi';
import { FileInput } from './FileInput';
import { TopNav } from './TopNav';

const SUCCESS_UPLOAD_STATUS = 200;

export interface UploadErrorShape {
  error: string;
  message: string;
}

declare global {
  interface Window {
    gtag: any;
  }
}

const Header = (): ReactElement => {
  const location = useLocation();
  const params = useParams();
  const paramsArr = params['*']?.split('/') || [];
  const paramsKey = paramsArr[paramsArr.length - 1] || '';
  const navigate = useNavigate();
  const { userState, setUserState } = useGlobalUserState();
  const [searchText, setSearchText] = useState('');
  const [isDirectoryOpen, setIsNewDirectoryOpen] = useState<boolean>(false);
  const [directoryName, setDirectoryName] = useState<string>('');
  const [directoryDescription, setDirectoryDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [filesToUpload, setFilesToUpload] = useState<number>(0);
  const [uploadError, setUploadError] = useState<UploadErrorShape | null>(null);
  const [batchSize, setBatchSize] = useState<number>(0);
  const [numberOfBatches, setNumberOfBatches] = useState<number>(0);
  const [completeBatches, setCompleteBatches] = useState<number>(0);
  const [requestFiles, setRequestFiles] = useState<boolean>(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>('');
  const [uploadAbortMessage, setUploadAbortMessage] = useState<string>('');
  const [uploadStatus, setUploadStatus] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<number>(0);
  const [filesUploadProgress, setFilesUploadProgress] = useState<number>(0);
  const [filesUploadProgressArr, setFilesUploadProgressArr] = useState<
    Array<object>
  >([]);
  const [totalFilesSize, setTotalFilesSize] = useState<number>(0);
  const [uploadToken, setUploadToken] = useState<string>('');
  const cardOnFile = userState?.data?.has_credit_card;

  useEffect(() => {
    if (window && window.gtag) {
      window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
        page_path: location.pathname,
      });
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const firstPath = location.pathname.split('/')[1];
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        canUpload: firstPath === 'files',
      },
    }));
  }, [location]);

  useEffect(() => {
    if (!isDirectoryOpen) {
      setDisabled(true);
      setDirectoryName('');
      setDirectoryDescription('');
    }
  }, [isDirectoryOpen]);

  useEffect(() => {
    directoryName && setDisabled(false);
  }, [directoryName]);

  useEffect(() => {
    if (filesUploadProgressArr.length) {
      const filesUploadProgressArrCopy = [...filesUploadProgressArr];
      const reversedArr = filesUploadProgressArrCopy.reverse();
      const lastAdded = reversedArr.shift();
      const previousAdded = reversedArr.find(
        (fileProgress) =>
          Object.keys(fileProgress)[0] === Object.keys(lastAdded!)[0]
      );
      if (previousAdded) {
        setFilesUploadProgress(
          (prevState) =>
            prevState +
            Object.values(lastAdded!)[0] -
            Object.values(previousAdded!)[0]
        );
      } else {
        setFilesUploadProgress(
          (prevState) => prevState + Object.values(lastAdded!)[0]
        );
      }
    }
  }, [filesUploadProgressArr]);

  useEffect(() => {
    if (uploadAbortMessage) {
      // eslint-disable-next-line no-console
      console.log('uploadAbortMessage: ' + uploadAbortMessage);
    }
  }, [uploadAbortMessage]);

  useEffect(() => {
    if (uploadErrorMessage) {
      // eslint-disable-next-line no-console
      console.log('uploadErrorMessage: ' + uploadErrorMessage);
    }
  }, [uploadErrorMessage]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (uploadStatus === SUCCESS_UPLOAD_STATUS) {
        await getStatus(uploadToken);
        if (uploadedFiles === filesToUpload) {
          setIsUploadOpen(false);
          setUploading(false);
          setFilesToUpload(0);
          setUserState((prevState: any) => ({
            ...prevState,
            data: {
              ...prevState.data,
              lastUpload: Date.now(),
            },
          }));
        }
      }
    })();
  }, [uploadStatus, uploadedFiles]);

  useEffect(() => {
    if (completeBatches && completeBatches === numberOfBatches) {
      setUploading(false);
    }
  }, [completeBatches]);

  const getStatus = async (token: string): Promise<void> => {
    try {
      const result = await callApiUpload<any>(
        'upload/get-status/',
        'GET',
        token,
        null
      );
      setCompleteBatches(result?.chunks_uploaded || 0);
      const error = result?.errorAtStage || '';
      const message = result?.errorExplanation || '';
      error && setUploadError({ error, message });
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const progressHandler = (e: ProgressEvent, i: number): void => {
    const loaded = e.loaded;
    setFilesUploadProgressArr((prevState) => [...prevState, { [i]: loaded }]);
  };
  const successHandler = (e: any): void => {
    setUploadedFiles((prevState) => prevState + 1);
    setUploadStatus(e.target?.status);
  };
  const errorHandler = (): void => {
    setUploadErrorMessage('upload failed!!');
  };
  const abortHandler = (): void => {
    setUploadAbortMessage('upload aborted!!');
  };

  const resetProgress = (): void => {
    setCompleteBatches(0);
    setTotalFilesSize(0);
    setFilesUploadProgress(0);
    setFilesUploadProgressArr([]);
  };

  const doUpload = async (fileArray: any): Promise<void> => {
    resetProgress();
    const fileCount: number = fileArray.length;
    let tempBatchSize: number;
    if (1 < fileCount && fileCount < 10) tempBatchSize = 1;
    else if (10 < fileCount && fileCount < 100) tempBatchSize = 10;
    else if (100 < fileCount && fileCount < 500) tempBatchSize = 20;
    else if (500 < fileCount && fileCount < 1000) tempBatchSize = 30;
    else tempBatchSize = 40;
    setBatchSize(tempBatchSize);
    const batchArray = Array.from(
      { length: Math.ceil(fileArray.length / tempBatchSize) },
      (value, index) =>
        fileArray.slice(
          index * tempBatchSize,
          index * tempBatchSize + tempBatchSize
        )
    );
    const tempNumberOfBatches = batchArray.length;
    setNumberOfBatches(tempNumberOfBatches);
    try {
      const token = await getToken(tempNumberOfBatches);
      if (token) {
        setUploadToken(token);
        setFilesToUpload(fileCount);
        setUploading(true);
        const xhr: XMLHttpRequest[] = [];
        for (const batchFileArray of batchArray) {
          setRequestFiles(true);
          const formData: FormData = new FormData();
          for (const file of batchFileArray) {
            formData.append('files', file);
            setTotalFilesSize((prevState) => prevState + file.size);
          }
          await callApiUploadXhr(
            formData,
            xhr,
            token,
            batchArray.indexOf(batchFileArray),
            progressHandler,
            successHandler,
            errorHandler,
            abortHandler
          );
          await getStatus(token);
          await callApiUpload<any>('upload/', 'POST', token, formData);
        }
      }
    } catch (err: any) {
      setError(err.message);
      alert(err);
      setUploading(false);
    } finally {
      setRequestFiles(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval: any;
    const getFiles = (): any => {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          lastUpload: Date.now(),
        },
      }));
    };
    if (requestFiles) {
      getFiles();
      interval = setInterval(() => getFiles(), 5000);
      return () => {
        clearInterval(interval);
      };
    } else {
      setTimeout(getFiles(), 2000);
      setTimeout(getFiles(), 4000);
      setTimeout(getFiles(), 6000);
      setTimeout(getFiles(), 10000);
      clearInterval(interval);
    }
  }, [requestFiles]);

  const getToken = async (chunkCount: number): Promise<string> => {
    let token: string = '';
    try {
      const result = await callApi<any>(
        'upload/get-token/',
        'POST',
        JSON.stringify({
          chunks_number: chunkCount,
          folder_upload_to: paramsKey || null,
        })
      );
      token = result.token;
      if (result.status !== 200) {
        setError(result.message);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
    return token;
  };
  const doSearch = (e: any): void => {
    e.keyCode === 13 && navigate(`/search?q=${searchText}`);
  };

  const createFolder = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await callApi<any>(
        'folder',
        'POST',
        JSON.stringify({
          comment: directoryDescription,
          name: directoryName,
          parent: paramsKey,
        })
      );
      !result?.success && setError(result.message);
      result?.created && setIsNewDirectoryOpen(false);
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          lastUpload: Date.now(),
        },
      }));
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const signOut = (): void => {
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        apiKeys: null,
        fileUsage: null,
        loggedIn: false,
        usage: null,
        pulse: null,
      },
    }));

    navigate('/');
  };
  return (
    <>
      <div className='Header'>
        <div className='Header--center'>
          <img
            className='Header--logo'
            style={{ cursor: 'pointer' }}
            src={logo}
            alt='dStor'
            height='23px'
            width='77px'
            onClick={(): void => {
              navigate(userState.data?.loggedIn ? '/files' : '/');
            }}
          />
          {userState.data?.loggedIn ? (
            <div className='Header--center-nav'>
              <input
                type='text'
                placeholder='Search files and hashes'
                value={searchText}
                onChange={(e): void => setSearchText(e.target.value)}
                onKeyDown={doSearch}
              />
              {userState.data?.canUpload && !userState.data?.isFolder && (
                <>
                  <button onClick={(): void => setIsUploadOpen(true)}>
                    Upload
                  </button>
                  <button onClick={(): void => setIsNewDirectoryOpen(true)}>
                    New Directory
                  </button>
                </>
              )}
            </div>
          ) : (
            <TopNav />
          )}

          {userState.data?.loggedIn ? (
            <div className='Header--pulse-grid'>
              <Pulse />
              <UserMenu name={userState.data?.name}>
                <ul className='Header--menu'>
                  <li
                    onClick={(): void => {
                      navigate('/files');
                    }}
                  >
                    Files
                  </li>
                  <li
                    onClick={(): void => {
                      navigate('/account/api-keys');
                    }}
                  >
                    Account
                  </li>
                  <li onClick={(): any => navigate('/billing/usage')}>
                    Billing
                  </li>
                  <li onClick={signOut}>Sign Out</li>
                </ul>
              </UserMenu>
            </div>
          ) : (
            <SignIn />
          )}
        </div>
      </div>
      <SideMenu
        isOpen={isDirectoryOpen}
        setIsOpen={setIsNewDirectoryOpen}
        position='CENTER'
      >
        <div className='Header--newfolder'>
          <Heading
            title='New Directory'
            subtitle='A directory does not create a hash and is used for organising files and folders. The contents can be edited.'
          />
          <ServerError error={error} />
          <TextInput
            type='text'
            name='Directory Name'
            value={directoryName}
            label
            setValue={setDirectoryName}
            focus
          />
          <TextInput
            type='text'
            name='Description'
            value={directoryDescription}
            label
            setValue={setDirectoryDescription}
            optional
          />
          <Button
            name='Create Directory'
            click={createFolder}
            loading={loading}
            disabled={disabled}
          />
        </div>
      </SideMenu>
      <SideMenu
        isOpen={isUploadOpen}
        setIsOpen={setIsUploadOpen}
        position='CENTER'
      >
        <>
          {!uploading && (
            <div className='Header--upload'>
              <Heading
                title='Upload'
                subtitle={
                  cardOnFile
                    ? 'Do you want to upload a file or a folder?'
                    : 'Oops! Before you upload!'
                }
              />
              {!cardOnFile && (
                <div>
                  Please add a valid credit card to your account. This ensures
                  smooth and uninterrupted service for you.
                  <a href='/billing/funding'> Let's get started!</a>
                </div>
              )}
              {cardOnFile && (
                <>
                  <ServerError error={error} />
                  <FileInput setFiles={doUpload} multiple>
                    <div className='Header--upload-btn'>
                      <h2 className='Header--upload-btn-title'>File</h2>
                      <p className='Header--upload-btn-desc'>
                        Upload a single or multiple files. A single hash for
                        each file will be created.
                      </p>
                    </div>
                  </FileInput>
                  <div className='Header--upload-or'>
                    <span>or</span>
                  </div>
                  <FileInput setFiles={doUpload} directory>
                    <div className='Header--upload-btn'>
                      <h2 className='Header--upload-btn-title'>Folder</h2>
                      <p className='Header--upload-btn-desc'>
                        Upload a folder along with its contents. Folders can not
                        be edited. the Folder will be addressable with a single
                        hash. <br />
                        <br />
                        The files within can be addressed by postfixing the file
                        name to the folders hash.
                      </p>
                    </div>
                  </FileInput>
                </>
              )}
            </div>
          )}
          {uploading && (
            <div className='Header--uploading'>
              <Heading
                title='Uploading'
                subtitle='Please be patient while your files are uploaded.'
              />
              {uploadError && (
                <ServerError
                  error={`${uploadError?.error} ${uploadError?.message}`}
                />
              )}
              <p>
                Uploading {filesToUpload} files in batches of {batchSize} files
              </p>
              <p>
                {numberOfBatches - completeBatches} / {numberOfBatches} batches
                left to upload.
              </p>
              <div className='Header--progress-bar'>
                {(completeBatches / numberOfBatches) * 100 < 100 && (
                  <div
                    className='Header--progress'
                    style={{
                      width: `${
                        (filesUploadProgress / totalFilesSize) * 100 > 100
                          ? 100
                          : (filesUploadProgress / totalFilesSize) * 100
                      }%`,
                    }}
                  ></div>
                )}
              </div>
            </div>
          )}
        </>
      </SideMenu>
    </>
  );
};
export { Header };
