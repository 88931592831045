import {
  createContext,
  useContext,
  useState,
  ReactElement,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { userStateShape } from '../interfaces/user';

interface PropsShape {
  children: ReactElement;
}

interface StateShape {
  userState: userStateShape;
  setUserState: Dispatch<SetStateAction<userStateShape>>;
}

const initVal: StateShape = {
  userState: JSON.parse(`${localStorage.getItem('userState')}`) || {},
  setUserState: () => {},
};

const GlobalContext = createContext<StateShape>(initVal);

const useGlobalUserState = (): StateShape => {
  return useContext(GlobalContext);
};

const GlobalUserState = ({ children }: PropsShape): ReactElement => {
  const [userState, setUserState] = useState<userStateShape>(initVal.userState);

  useEffect(() => {
    localStorage.setItem('userState', JSON.stringify(userState));
  }, [userState]);

  useEffect(() => {
    const userStateString = localStorage.getItem('userState');
    userStateString && setUserState(JSON.parse(userStateString));
  }, []);

  const value = {
    userState,
    setUserState,
  };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export { GlobalUserState, useGlobalUserState };
