/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useState, useEffect } from 'react';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from './Button';

export interface PropsShape {
  stripeClientSecret: string;
}
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const SaveCard = ({ stripeClientSecret }: PropsShape): ReactElement => {
  const options = {
    clientSecret: stripeClientSecret!,
    appearance: {},
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm />
    </Elements>
  );
};

const SetupForm = (): ReactElement => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );

    if (clientSecret) {
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        switch (setupIntent?.status) {
          case 'succeeded':
            setMessage('Success! Your payment method has been saved.');
            break;
          case 'processing':
            setMessage(
              "Processing payment details. We'll update you when processing is complete."
            );
            break;
          case 'requires_payment_method':
            setMessage(
              'Failed to process payment details. Please try another payment method.'
            );
            break;
        }
      });
    }
  }, [stripe]);

  const handleSubmit = async (event: any): Promise<any> => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_FRONT_END_URL}/billing/funding`,
      },
    });
    if (error) {
      setErrorMessage(error.message!);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      {message}
      <PaymentElement />
      <Button name='Save Card' type='submit' />
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};
export { SaveCard };
