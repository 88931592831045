import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';
import { Menu } from '../../components/Menu';
import { callApi } from '../../functions/callApi';
import { readableBytes, centsToDollars } from '../../functions/formatters';
import { fileUsageShape } from '../../interfaces/user';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { SideDataGrid } from '../../components/SideDataGrid';
import { SideDataGridItem } from '../../components/SideDataGridItem';
import { DateTime } from '../../components/DateTime';

function Usage(): ReactElement {
  const navigate = useNavigate();
  const { userState, setUserState } = useGlobalUserState();
  const [selectedFile, setSelectedFile] = useState<fileUsageShape>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [maxOffset, setMaxOffset] = useState<number>(0);

  const downloadCsv = (csvData: string, fileName: string): void => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = async (): Promise<void> => {
    const csvData = await callApi<any>(`billing/files-usage-csv`);
    const fileName = 'file-usage.csv';
    downloadCsv(csvData, fileName);
  };

  const handleScroll = (e: any): void => {
    e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      getFileUsage(maxOffset + 20);
  };

  const getFileUsage = async (offset: number): Promise<void> => {
    setMaxOffset(offset);
    const sendOffset = offset === 0 ? 0 : offset + 20;
    const fileUsage = await callApi<any>(
      `billing/files-usage?limit=20&offset=${sendOffset}`,
      'GET'
    );
    if (fileUsage.results.length > 0) {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          fileUsage:
            offset === 0
              ? [...fileUsage.results]
              : [...prevState.data.fileUsage, ...fileUsage.results],
        },
      }));
    }
  };

  useEffect(() => {
    const getUsage = async (): Promise<void> => {
      const usage = await callApi<any>('billing/overview', 'GET');
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          usage,
        },
      }));
    };
    getUsage();
    getFileUsage(0);
  }, []);

  useEffect(() => {
    !userState.data?.loggedIn && navigate('/');
  }, [userState]);

  const calcNav = (): any[] => {
    const navArr = [
      { name: 'Usage', path: '/billing/usage' },
      { name: 'Funding', path: '/billing/funding' },
      { name: 'Invoices', path: '/billing/invoices' },
    ];
    return navArr;
  };

  const selectFile = (x: any): void => {
    const fileArr = userState?.data?.fileUsage;
    if (fileArr) {
      const selected: fileUsageShape = fileArr[x.target.id] as fileUsageShape;
      setSelectedFile(selected as fileUsageShape);
      setIsOpen(true);
    }
  };

  return (
    <div className='Usage'>
      <Header />
      <div className='Usage--center'>
        <div className='Usage--title'>Billing</div>
        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          position='RIGHT'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title={selectedFile?.file_name || ''}
              subtitle='This is the files usage and associated costs.'
            />
            <div>
              <br></br>
              <br></br>
              <SideDataGrid bottomArrow description='Download'>
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Count: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: ` ${selectedFile?.downloads_count}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Size: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: ` ${readableBytes(
                          selectedFile?.download_size_bytes!
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Cost: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `$ ${centsToDollars(
                          selectedFile?.download_price_cents!,
                          6
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
              </SideDataGrid>
              <SideDataGrid description='Storage' bottomArrow>
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Size: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `${readableBytes(
                          selectedFile?.storage_size_bytes!
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Days: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `${(
                          (selectedFile?.stored_milliseconds || 0) /
                          (24 * 60 * 60 * 1000)
                        ).toFixed(1)}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Cost: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `$ ${centsToDollars(
                          selectedFile?.storage_price_cents!,
                          6
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
              </SideDataGrid>
              <SideDataGrid description='Upload' bottomArrow>
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Size: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `${readableBytes(
                          selectedFile?.upload_size_bytes!
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Cost: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `$ ${centsToDollars(
                          selectedFile?.upload_price_cents!,
                          6
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
              </SideDataGrid>
              <SideDataGrid title>
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Total cost: ',
                        weight: 'bold',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `$ ${centsToDollars(
                          selectedFile?.total_price_cents!,
                          6
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
              </SideDataGrid>
            </div>
          </div>
        </SideMenu>
        <div className='Usage--grid'>
          <div>
            <Menu items={calcNav()} selected='Usage' />
          </div>
          <div>
            <div className='Usage--info'>
              <div className='Usage--info-title'>
                <div className='Usage--info-title-text'>Dashboard</div>

                {userState.data?.usage?.next_billing_date && (
                  <div className='Usage--next-invoice'>
                    Next Invoice:{' '}
                    <b>
                      <DateTime
                        showTime
                        value={
                          new Date(userState.data?.usage?.next_billing_date!)
                        }
                      />
                    </b>
                  </div>
                )}
              </div>
              <div className='Usage--rate-grid'>
                <div className='Usage--rate-item'>
                  <div className='Usage--rate-title'>
                    Storage {userState.data?.usage?.storage_month_gb}
                  </div>
                  <div className='Usage--rate-value'>
                    $
                    {centsToDollars(
                      userState.data?.usage?.storage_price_us_cents || 0
                    )}
                  </div>
                </div>
                <div className='Usage--rate-item'>
                  <div className='Usage--rate-title'>
                    Upload{' '}
                    {userState.data?.usage?.uploaded_month_gb?.toFixed(4)}
                  </div>
                  <div className='Usage--rate-value'>
                    $
                    {centsToDollars(
                      userState.data?.usage?.upload_price_us_cents || 0.0
                    )}
                  </div>
                </div>
                <div className='Usage--rate-item'>
                  <div className='Usage--rate-title'>
                    Download{' '}
                    {userState.data?.usage?.downloaded_month_gb?.toFixed(4)}
                  </div>
                  <div className='Usage--rate-value'>
                    $
                    {centsToDollars(
                      userState.data?.usage?.download_price_us_cents || 0.0
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='Users--info'>
              <div className='Users--info-title'>
                <div className='Users--info-title-text'>Files</div>
              </div>
              {userState.data?.fileUsage && (
                <div className='Users--info-header'>
                  <div>Name</div>
                  <div>Size</div>
                  <div>Cost</div>
                </div>
              )}
              <div className='Usage--scroll' onScroll={handleScroll}>
                {userState?.data?.fileUsage?.map((key: any, index: any) => (
                  <div
                    id={index}
                    key={index}
                    className='Users--info-row'
                    onClick={selectFile}
                  >
                    <div>{key.file_name}</div>
                    <div>{readableBytes(key.storage_size_bytes)}</div>
                    <div>${(key.total_price_cents! / 100).toFixed(6)}</div>
                  </div>
                ))}
              </div>
              {!userState.data?.fileUsage && (
                <div className='Users--none'>
                  No file usage has been recorded since your last invoice.
                </div>
              )}

              <div
                onClick={handleDownload}
                style={{
                  padding: '12px',
                  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                  fontWeight: '700',
                  cursor: 'pointer',
                }}
              >
                Download CSV
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Usage;
