import { ReactElement, useState, useEffect } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { Menu } from '../../components/Menu';
import { SettingsItem } from '../../components/SettingsItem';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { callApi } from '../../functions/callApi';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { ServerError } from '../../components/ServerError';
import { AvatarPicker } from '../../components/AvatarPicker';

import image from '../../images/sean-anderson.jpg';
import defaultImage from '../../images/no-avatar.jpg';

function Settings(): ReactElement {
  const { userState, setUserState } = useGlobalUserState();
  // const [ setToggleLoading] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(
    userState.data?.isPreviewOn || false
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [profileLoading, setProfileLoading] = useState<boolean>(false);
  const [profileDisabled, setProfileDisabled] = useState<boolean>(false);
  const [editName, setEditName] = useState<string>(userState.data?.name || '');

  useEffect(() => {
    const togglePreview = async (): Promise<void> => {
      await callApi<any>(
        `account/toggle-preview`,
        'PATCH',
        JSON.stringify({ is_preview_on: showPreview })
      );
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          isPreviewOn: showPreview,
        },
      }));
    };
    togglePreview();
  }, [showPreview]);

  const calcNav = (): any[] => {
    const navArr = [
      { name: 'Security', path: '/account/security' },
      { name: 'Notifications', path: '/account/notifications' },
      { name: 'Settings', path: '/account/settings' },
      { name: 'API Keys', path: '/account/api-keys' },
    ];
    if (
      userState.data?.userRole === 'SUPERADMIN' ||
      userState.data?.userRole === 'OUTPOST_HANDLER' ||
      userState.data?.isNodeOperator
    ) {
      navArr.push({ name: 'Storage Nodes', path: '/account/nodes' });
    }
    userState.data?.userRole === 'SUPERADMIN' &&
      navArr.push({ name: 'Users', path: '/account/users' });
    userState.data?.userRole === 'ADMIN' &&
      navArr.push({ name: 'Users', path: '/account/users' });
    return navArr;
  };
  const togglePreview = async (): Promise<void> => {
    // setToggleLoading(true);
    setShowPreview(!showPreview);
    // setToggleLoading(false);
  };

  const saveProfile = async (): Promise<void> => {
    setServerError('');
    setProfileLoading(true);
    setProfileDisabled(false);
  };

  return (
    <div className='ApiKeys'>
      <Header />
      <div className='ApiKeys--center'>
        <div className='ApiKeys--title'>Account</div>
        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title='Edit Name & Avatar'
              subtitle='Edit your name and upload an avatar'
            />
            <ServerError error={serverError} />

            <TextInput
              type='text'
              name='Name'
              value={editName}
              setValue={setEditName}
              label
            />
            <AvatarPicker
              onImageUpload={(): void => {}}
              initialImage={image}
              noImage={defaultImage}
            />

            <Button
              name='Save'
              click={saveProfile}
              loading={profileLoading}
              disabled={profileDisabled}
            />
          </div>
        </SideMenu>
        <div className='ApiKeys--grid'>
          <div>
            <Menu items={calcNav()} selected='Settings' />
          </div>

          <Card>
            <SettingsItem title='Show File Preview' subtitle=''>
              <Card>
                <SettingsItem
                  inner
                  title='Toggle Previews'
                  subtitle='Show or hide file previews in the file side bar'
                >
                  <Button
                    name={showPreview ? 'On' : 'Off'}
                    margin={0}
                    click={togglePreview}
                  />
                </SettingsItem>
              </Card>
            </SettingsItem>
            {/* <SettingsItem title='Profile' subtitle=''>
              <Card>
                <SettingsItem
                  inner
                  title='Name & Avatar'
                  subtitle='Edit your name and/or avatar'
                >
                  <Button
                    name={'Edit'}
                    margin={0}
                    click={(): void => setIsOpen(true)}
                    loading={toggleLoading}
                  />
                </SettingsItem>
              </Card>
            </SettingsItem> */}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Settings;
