import React, { useState } from 'react';
import removeImage from '../images/remove-grey.svg';
interface AvatarProps {
  initialImage: string;
  noImage: string;
  onImageUpload: (image: File) => void;
}

const AvatarPicker: React.FC<AvatarProps> = ({
  initialImage,
  noImage,
  onImageUpload,
}) => {
  const [avatarImage, setAvatarImage] = useState<string>(initialImage);

  const handleAvatarClick = (): void => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.addEventListener('change', (event: any) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          const image = new Image();
          image.src = reader.result as string;
          image.onload = (): void => {
            const canvas = document.createElement('canvas');
            canvas.width = 50;
            canvas.height = 50;
            const ctx = canvas.getContext('2d')!;
            ctx.drawImage(image, 0, 0, 50, 50);
            canvas.toBlob(
              (blob) => {
                const resizedImage = new File([blob as Blob], file.name, {
                  type: 'image/jpeg',
                });
                setAvatarImage(URL.createObjectURL(resizedImage));
                onImageUpload(resizedImage);
              },
              'image/jpeg',
              0.9
            );
          };
        };
      }
    });
    input.click();
  };

  const handleNoImageButtonClick = (): void => {
    setAvatarImage(noImage);
    onImageUpload(null as any);
  };

  const avatarStyle: React.CSSProperties = {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundImage: `url(${avatarImage})`,
    backgroundPosition: 'center',
    border: '1px solid #a3a3a3',
    cursor: 'pointer',
  };

  return (
    <div>
      <div
        style={{ color: '#434343', fontWeight: '200', marginBottom: '10px' }}
      >
        Avatar:
      </div>
      <div style={avatarStyle} onClick={handleAvatarClick}></div>
      <button
        onClick={handleNoImageButtonClick}
        style={{
          backgroundColor: 'white',
          backgroundImage: `url(${removeImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '20px',
          height: '20px',
          position: 'relative',
          right: '-45px',
          top: '-55px',
          border: 'none',
          borderRadius: '50%',
          opacity: '0.5',
        }}
      ></button>
    </div>
  );
};

export { AvatarPicker };
