import { ReactElement, useEffect, useState } from 'react';
import { FileView } from './FileView';
import { ViewSelector } from './ViewSelector';
import { FileShape } from '../interfaces/files';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { callApi } from '../functions/callApi';

export interface BreadcrumbShape {
  id: number;
  name: string;
}

const SearchResults = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const searchParam = searchParams.get('q') || '';
  const navigate = useNavigate();
  const [cleanFiles, setCleanFiles] = useState<FileShape[]>();
  const [view, setView] = useState<'ROW' | 'SMALL' | 'LARGE'>('ROW');
  const [resultCount, setResultCount] = useState<number>();

  useEffect(() => {
    const getFiles = async (): Promise<void> => {
      const url: string = `files/search?s=${searchParam}&limit=20&offset=0`;
      const res = await callApi<any>(url);
      const files = res.results;

      const fixFiles = (i: any): any => {
        const item: FileShape = {
          folder: `undefined`, // TODO: add folder in here when search returns it
          type: 'FILE',
          created: i.created,
          hash: i.file_hash || i.folder_hash,
          name: i.file_name || i.folder_name,
          size: i.file_size,
        };
        if (item !== undefined && i.is_folder === false) {
          return item;
        }
      };

      let fixedFiles = files?.map(fixFiles);
      fixedFiles = fixedFiles.filter((e: any) => e !== undefined);
      setResultCount(fixedFiles.length);
      setCleanFiles(fixedFiles);
    };
    getFiles();
  }, [searchParam]);

  const calcLayout = (): string => {
    return view === 'ROW' ? 'FileBrowser--grid-row' : 'FileBrowser--grid';
  };

  return (
    <>
      <div className='FileBrowser'>
        <div className='FileBrowser--breadcrumbs'>
          <div
            className='FileBrowser--breadcrumbs-root'
            onClick={(): void => {
              navigate('/files');
            }}
          >
            {resultCount} Results for "{searchParams.get('q')}"
          </div>
        </div>
      </div>
      <div>
        {resultCount! > 0 && (
          <div className='FileBrowser--view-control'>
            <div />
            <ViewSelector setView={setView} selected={view || 'ROW'} />
          </div>
        )}
        {cleanFiles?.map((file, index) => (
          <div key={index} className={calcLayout()}>
            <FileView data={file} layout={view} />
          </div>
        ))}
      </div>
    </>
  );
};
export { SearchResults };
