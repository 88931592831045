import { ReactElement, useState, useEffect } from 'react';
import { FileShape } from '../interfaces/files';
import { DateTime } from './DateTime';
import { Copy } from './Copy';
import { FormatBytes } from './FormatBytes';
import { Button } from './Button';
import SideMenu from './SideMenu';
import { TextInput } from './TextInput';
import { callApi } from '../functions/callApi';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { SelectInput } from './SelectInput';

export interface PropsShape {
  data: FileShape;
  layout: 'ROW' | 'SMALL' | 'LARGE';
  folders?: FileShape[];
}

const FileView = ({ data, layout, folders }: PropsShape): ReactElement => {
  const { userState, setUserState } = useGlobalUserState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<string>('');
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [renameLoading, setRenameLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [rename, setRename] = useState<string>(data.name);
  const [display, setDisplay] = useState<boolean>(false);
  const [moveTo, setMoveTo] = useState<string>('');
  const [moveLoading, setMoveLoading] = useState<boolean>(false);

  useEffect(() => {
    setConfirmDelete('');
  }, [isOpen]);

  useEffect(() => {
    confirmDelete === 'delete' && setDeleteDisabled(false);
  }, [confirmDelete]);

  const canDisplay = (): void => {
    setDisplay(true);
  };
  const doDownload = async (): Promise<void> => {
    setDownloadLoading(true);
    const preview = document.getElementById('preview');

    if (preview) {
      const previewSrc = preview.getAttribute('src');
      if (previewSrc) {
        const fileData = await fetch(previewSrc);
        const blob = await fileData.blob();
        const blobUrl = URL.createObjectURL(blob);
        const download = document.createElement('a');
        download.setAttribute('href', blobUrl);
        download.setAttribute('download', data.name);
        document.body.appendChild(download);
        download.click();
        download.remove();
        setDownloadLoading(false);
      }
    } else {
      window.open(`${process.env.REACT_APP_API_IPFS_URL}${data.hash}`);
    }
  };
  const doDelete = async (): Promise<void> => {
    setDeleteLoading(true);
    const url = 'file';
    const body = {
      file_hash: data.hash,
      file_key: data.key,
    };
    try {
      await callApi(url, 'DELETE', JSON.stringify(body));
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          lastUpload: Date.now(),
        },
      }));
      setDeleteLoading(false);
      setIsOpen(false);
    }
  };

  const doRename = async (): Promise<void> => {
    setRenameLoading(true);
    const url = 'file/rename';
    const body = {
      file_hash: data.hash,
      file_name: rename,
      file_key: data.key,
    };
    await callApi(url, 'PATCH', JSON.stringify(body));
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        lastUpload: Date.now(),
      },
    }));
    setRenameLoading(false);
    setIsOpen(false);
  };

  const doMove = async (): Promise<void> => {
    setMoveLoading(true);
    const url = 'file/move';
    const body = {
      file_key: data.key,
      folder_key: moveTo,
    };
    try {
      await callApi(url, 'PATCH', JSON.stringify(body));
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setMoveLoading(false);
      setIsOpen(false);
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          lastUpload: Date.now(),
        },
      }));
    }
  };

  const calcFolders = (): any => {
    if (folders) {
      const foldersArr = [];
      for (let i = 0; i < folders.length; i++) {
        foldersArr.push({
          value: `${folders[i].key}`,
          name: folders[i].name,
        });
      }

      return foldersArr;
    }
  };
  return (
    <>
      {layout === 'SMALL' && (
        <div
          className='FileView'
          onClick={(): void => setIsOpen(true)}
          onContextMenu={(e: any): void => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <div className='FileView--top'>
            <div className='FileView--top-name'>{data.name}</div>
          </div>
          <div className='FileView--bottom'>
            <Copy
              value={`${process.env.REACT_APP_API_IPFS_URL}${data.hash}/`}
              type='TEXT'
              text={data.hash}
            />
          </div>
        </div>
      )}
      {layout === 'LARGE' && (
        <div
          className='FileView'
          onClick={(): void => setIsOpen(true)}
          onContextMenu={(e: any): void => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <div className='FileView--top'>
            <div className='FileView--top-folder' />
            <div className='FileView--top-name'>{data.name}</div>
            <div className='FileView--top-date'>
              <DateTime showTime value={new Date(data.created)} />
            </div>
          </div>
          <div className='FileView--bottom'>
            <Copy
              value={`${process.env.REACT_APP_API_IPFS_URL}${data.hash}/`}
              type='TEXT'
              text={data.hash}
            />
          </div>
        </div>
      )}
      {layout === 'ROW' && (
        <div
          className='FileViewRow'
          onClick={(): void => setIsOpen(true)}
          onContextMenu={(e: any): void => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <div className='FileViewRow--folder' />
          <div className='FileViewRow--name'>{data.name}</div>
          <div className='FileViewRow--date'>
            <DateTime showTime value={new Date(data.created)} />
          </div>
          <div className='FileViewRow--size'>
            <FormatBytes value={+data.size!} />
          </div>
          <div className='FileViewRow--bottom'>
            <Copy
              value={`${process.env.REACT_APP_API_IPFS_URL}${data.hash}/`}
              type='TEXT'
              text={data.hash}
            />
          </div>
        </div>
      )}
      <SideMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        position='RIGHT'
        width='REGULAR'
      >
        <div className='FileViewRow--grid'>
          <div>
            <div className='FileViewRow--section'>
              {userState.data?.isPreviewOn && (
                <img
                  src={`${process.env.REACT_APP_API_IPFS_URL}${data.hash}/`}
                  id='preview'
                  onLoad={(): void => canDisplay()}
                  className={
                    display ? 'FileViewRow--show' : 'FileViewRow--hide'
                  }
                ></img>
              )}
              <div className='FileViewRow--info'>
                <div className='FileViewRow--info-left'>Name</div>
                <div className='FileViewRow--info-right'>{data.name}</div>
                <div className='FileViewRow--info-left'>Created</div>
                <div className='FileViewRow--info-right'>
                  <DateTime showTime value={new Date(data.created)} />
                </div>
                <div className='FileViewRow--info-left'>Size</div>
                <div className='FileViewRow--info-right'>
                  <FormatBytes value={+data.size!} />
                </div>
                <div className='FileViewRow--info-left'>Hash</div>
                <div className='FileViewRow--info-right'>{data.hash}</div>
              </div>

              <Button
                name='Download'
                color='GREY'
                loading={downloadLoading}
                click={doDownload}
              />
            </div>
          </div>
          <div />
          <div>
            <hr className='FileViewRow--divider' />
            <div className='FileViewRow--section'>
              <div style={{ maxWidth: '100%' }}>
                <div style={{ color: '#838383', marginBottom: '3px' }}>
                  Move file to...
                </div>
                <SelectInput
                  name='Move file to'
                  value={moveTo}
                  setValue={setMoveTo}
                  options={calcFolders()}
                />
              </div>
              <Button
                name='Move'
                color='BLUE'
                loading={moveLoading}
                click={doMove}
              />
            </div>
          </div>
          <div>
            <hr className='FileViewRow--divider' />
            <div className='FileViewRow--section'>
              <TextInput
                name='File name'
                value={rename}
                setValue={setRename}
                label
              />
              <Button
                name='Rename'
                color='BLUE'
                loading={renameLoading}
                click={doRename}
              />
            </div>
          </div>
          {userState.data?.canUpload && !userState.data?.isFolder && (
            <div>
              <hr className='FileViewRow--divider' />
              <div className='FileViewRow--section'>
                <TextInput
                  name='Confirm delete'
                  value={confirmDelete}
                  setValue={setConfirmDelete}
                  placeHolder="Enter 'delete' to confirm"
                  label
                />
                <Button
                  name='Delete'
                  color='RED'
                  disabled={deleteDisabled}
                  click={doDelete}
                  loading={deleteLoading}
                />
              </div>
            </div>
          )}
        </div>
      </SideMenu>
    </>
  );
};
export { FileView };
