import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';
import { Menu } from '../../components/Menu';
import { callApi } from '../../functions/callApi';
import { SaveCard } from '../../components/SaveCard';
import { Button } from '../../components/Button';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { fundingShape } from '../../interfaces/user';

function Funding(): ReactElement {
  const navigate = useNavigate();
  const { userState, setUserState } = useGlobalUserState();
  const [clientSecret, setClientSecret] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const getPaymentDetails = async (): Promise<void> => {
    const funding = await callApi<any>('account/payment-details', 'GET');
    if (!funding.error) {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          has_credit_card: !!funding?.[0]?.card_last_four,
          funding,
        },
      }));
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  useEffect(() => {
    !userState.data?.loggedIn && navigate('/');
  }, [userState]);

  const calcNav = (): any[] => {
    const navArr = [
      { name: 'Usage', path: '/billing/usage' },
      { name: 'Funding', path: '/billing/funding' },
      { name: 'Invoices', path: '/billing/invoices' },
    ];
    return navArr;
  };

  const doAddCard = async (): Promise<void> => {
    const response = await callApi<any>(
      'payment/setup-intent-client-secret',
      'GET'
    );
    const clientSecret = response.secret;
    setClientSecret(clientSecret);
    setIsOpen(true);
  };
  const doRemove = async (i: number): Promise<void> => {
    if (userState.data?.funding) {
      await callApi<any>(
        `account/payment-details/${userState.data?.funding[i].id}/`,
        'DELETE'
      );
      await getPaymentDetails();
    }
  };

  return (
    <div className='Funding'>
      <Header />
      <div className='Funding--center'>
        <div className='Funding--title'>Billing</div>
        <div className='Funding--grid'>
          <div>
            <Menu items={calcNav()} selected='Funding' />
          </div>
          <div className='Funding--card'>
            <div className='Funding--card-section'>
              <div className='Funding--card-info'>
                <div className='Funding--card-title'>Cards</div>
                <div className='Funding--card-description'>
                  These are the credit cards we have on file for you, on your
                  billing date we will try each card in the order shown.
                </div>
                <Button name='Add Card' click={doAddCard} />
              </div>
              <div className='Funding--card-info'>
                <div className='Funding--card-card'>
                  {userState?.data?.funding?.map(
                    (card: fundingShape, index: number) => {
                      return (
                        <div className='Funding--card-item' key={index}>
                          <div>
                            <div className='Funding--card-number'>
                              **** **** **** {card.card_last_four}
                            </div>
                            <div className='Funding--card-expiry'>
                              {card.card_exp_month} / {card.card_exp_year}
                            </div>
                          </div>

                          <div>
                            <Button
                              name='Remove'
                              margin={0}
                              click={(): Promise<void> => doRemove(index)}
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                  {!userState?.data?.funding?.length && (
                    <div style={{ textAlign: 'center', padding: '12px' }}>
                      Please add a card to fund your account
                    </div>
                  )}
                </div>
              </div>
            </div>

            <SideMenu isOpen={isOpen} setIsOpen={setIsOpen} width='REGULAR'>
              <div className='Funding--modal'>
                <Heading
                  title='Add Card'
                  subtitle='Enter your card details below to add this card to your funding methods'
                />
                {clientSecret && <SaveCard stripeClientSecret={clientSecret} />}
              </div>
            </SideMenu>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Funding;
